import React from 'react';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import axiosInstance from '../../Shared/Interceptor/interceptor';
import appConfig from '../../../Environment/environments';
import { ReactComponent as NoUser } from '../../../Assests/Images/NoUser.svg';
import DialogBox from "../OptionsMenu/dialog";
import {MOVEUSERTO,USERACTIVE,GROUPUSERAPI,APPUSERACTIVE,
  APPUSERAPI,DELETETEXT,LOADINGTEXT,NOADMINDATA,NOUSERTEXT} from '../../../Common/Constants/constants';
import { encryptData } from '../../../Common/Utilities/utility';
import RightDrawer from '../../Shared/RightDrawer/RightDrawer';
import ChangeGroup from '../../Groups/changeGroup/changeGroup';

class Table extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changedialogWidth:{width:600},
      filtered: [],
      id:"",
      toastHPosition: 'right',
      right: false,
      groupGrid : false,
      userId : 0,
      groupId : 0,
    }
    this.onClickRemoveItem = this.onClickRemoveItem.bind(this);
    this.onhandleChangeActive = this.onhandleChangeActive.bind(this);
    this.onClickMoveItem = this.onClickMoveItem.bind(this);
    this.toggleDrawer = this.toggleDrawer.bind(this);
  }

  componentDidMount() {
    this.setState({
      filtered: this.props.items,
      groupId:this.props.groupId,
      groupGrid:(this.props.type === 'group') ? true : false
    });
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      filtered: nextProps.items
    });
  }
  toggleDrawer = (side, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
  }
  onhandleChangeActive(entry) {
    let currentList = this.props.items;
    let newList = currentList.map((item) => {
      let result = (this.props.type === 'group') ? (entry.groupUserId === item.groupUserId) :  (entry.appUserId == item.appUserId);
      if ( result ) {
        item.isActive = !item.isActive;
        let URL = (this.props.type === 'app') ? (APPUSERACTIVE + encryptData(item.appUserId).toString()) : (USERACTIVE + encryptData(item.groupUserId));
        axiosInstance.put(appConfig.api.development + URL  + "&userStatus=" + encryptData(item.isActive) , {
          method: 'PUT',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        })
      }
      return item;
    });
    this.setState({ filtered: newList });
    this.props.onContentChange && this.props.onContentChange();
  }

  onClickRemoveItem(entry, index) {
    this.setState({ 
      opennow: true,
      id: (this.props.type === 'group') ? entry.groupUserId :  entry.appUserId
    });
  }
  onClickMoveItem(entry) {
    this.setState({ 
      right: true,
      userId : entry.userId,
      id: (this.props.type === 'group') ? entry.groupUserId :  entry.appUserId
    });
    this.toggleDrawer('right', true);
  }

  onCloseEvent = (props) => {
    this.setState({ opennow: false })
  }

  onCloseParentPopUp = () =>{
    this.setState({ 
      right: false
    }); 
  }
  onCloseSaveClick = () =>{
    let currentList = this.props.items;
    let mylist;
    currentList.map((item,itemIndex) => {
      let result = (this.props.type === 'group') ? (this.state.id === item.groupUserId) :  (this.state.id == item.appUserId);
      if ( result ) {
        mylist=itemIndex 
        return item;
      }
    });
    currentList.splice(mylist,1)
    this.setState({ filtered: currentList });
    this.props.onContentChange && this.props.onContentChange();
  }

  handleCloseDialogue = () => {
   let currentList = this.props.items;
    let mylist
    this.setState({ opennow: false });
    currentList.map((item,itemIndex) => {
      let result = (this.props.type === 'group') ? (this.state.id === item.groupUserId) :  (this.state.id == item.appUserId);
      if ( result ) {
        let URL = (this.props.type === 'app') ? (APPUSERAPI + encryptData(item.appUserId).toString()): (GROUPUSERAPI + encryptData(item.groupUserId).toString());
        axiosInstance.delete(appConfig.api.development + URL, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          }
        })
        mylist=itemIndex 
        return item;
      }
    });
    currentList.splice(mylist,1)
    this.setState({ filtered: currentList });
    this.props.onContentChange && this.props.onContentChange();
  };

  render() {
    return (
      <div className="App">
        <div className="table">
          <TableHeader gridType={this.props.gridType} type={this.props.type}/>
          {
            (this.state.filtered.length != 0) ? this.state.filtered.map((item, idx) =>
              <TableBody
                key={idx}
                {...item}
                onClickRemoveItem={this.onClickRemoveItem}
                onhandleChangeActive={this.onhandleChangeActive}
                onClickMoveItem ={this.onClickMoveItem}
                type={this.props.type}
              />
            ) : ((this.state.filtered.length == 0 && this.props.initialRender == false) ?
              <div className="noUsersInfo">
                <p className="noUsersContent">{LOADINGTEXT}</p>
              </div> :
              <div className="noUsersInfo">
                <span className="noUsersIcon">
                  <NoUser />
                </span>
                <p className="noUsersContent">{(this.props.gridType === 0) ? NOADMINDATA : NOUSERTEXT}</p>
              </div>)
          }
        </div>
        <DialogBox dialogContent={this.props.dialogContent} dialogHead={this.props.dialogHead} dialogButtonText={DELETETEXT} opennow={this.state.opennow} onCloseClick={this.handleCloseDialogue} onCloseEvent={this.onCloseEvent} />
        <RightDrawer customProps={this.state.changedialogWidth} open={this.state.right}>
          <ChangeGroup 
            callApiOnSave
            headerLabel={MOVEUSERTO}
            saveType="moveUser"
            userId={this.state.userId}
            GroupId={this.state.groupId}
            onSave ={this.onCloseSaveClick}
            onClose={this.onCloseParentPopUp} />
        </RightDrawer>
      </div>
    );
  }
}

export default Table;