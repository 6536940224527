// const tenant = 'unob2c.onmicrosoft.com';
// const version = '1.1.1.0';
// const loginURL = 'https://unob2c.b2clogin.com/';

// export const appConfig = {
//   api: {
//     development: 'https://devapi.carrierappshub.com', //https://ngecatum.azurewebsites.net/
//     clientID: '7e9a741a-cbf6-4abc-bf6d-0cfade927b0b', // This is for local env.. for deployment use this guy -> 63fe9b41-f48c-4098-bc6c-d691ad542355 -> B2C - Application
//     authority:
//       loginURL + tenant + '/B2C_1_CSS_SUSI_POL', // Sign up and Sign In Policy
//     forgotpasswordAuthority:
//       loginURL + tenant + '/B2C_1_CSS_ResetPassword/', // Forget password policy name
//     clientScope1: 'https://' + tenant + '/umdevapi/user_impersonation',
//     postLogoutRedirectUri: 'https://app-diapasonumui-dev.azurewebsites.net/',
//     encryption: {
//       IV: "8080808080808080",
//       Key: "8080808080808080"
//     }, 
//     version: version,
//     tenant: tenant,
//     authorityBase: loginURL,
//     authorityURL: 'B2C_1_CSS_SUSI_POL'
//   },
// }

// export default appConfig;


















const tenant = 'unob2c.onmicrosoft.com';
const version = '1.1.1.0';
const loginURL = 'https://unob2c.b2clogin.com/';
const prodconfig = {
  apiUrl: 'https://api.carrierappshub1.com/', //'https://devapi.carrierappshub.com/',
  uiUrl: 'https://app-diapasonumui-dev.azurewebsites.net/',
  // // userPoolId: "us-east-2_rjmskKvmB",
  // // identityPoolId: "us-east-2:d134dd1a-560b-46cf-a917-c9aae0684eea",
  // // clientId: "4pf6qvsk8b2rd9n1raj22ka18b",
  // // domain: "carrierum.auth.us-east-2.amazoncognito.com",
  postLogOutUri: 'https://app-diapasonumui-dev.azurewebsites.net',
  redirectAppUri: 'https://auth.carrierappshub1.com',
  appType: 'CSS',
  authenticateOnly: true,
}

const devconfig = {
  apiUrl: 'https://devapi.carrierappshub1.com/', //'https://api.carrierappshub.com/', //'https://devapi.carrierappshub.com/',
  uiUrl: 'https://app-diapasonumui-dev.azurewebsites.net/',
  userPoolId: "us-east-2_rjmskKvmB",
  identityPoolId: "us-east-2:d134dd1a-560b-46cf-a917-c9aae0684eea",
  clientId: "4pf6qvsk8b2rd9n1raj22ka18b",
  domain: "carrierum.auth.us-east-2.amazoncognito.com",
  postLogOutUri: 'https://app-diapasonumui-dev.azurewebsites.net',
  redirectAppUri: 'https://authdev.carrierappshub1.com',
  appType: 'CSS',
  authenticateOnly: true,
}

export const appConfig = {
  api: {
    development: 'https://devapi.carrierappshub1.com', //'https://api.carrierappshub.com', //'https://devapi.carrierappshub.com', //https://ngecatum.azurewebsites.net/
    clientID: '7e9a741a-cbf6-4abc-bf6d-0cfade927b0b', // This is for local env.. for deployment use this guy -> 63fe9b41-f48c-4098-bc6c-d691ad542355 -> B2C - Application
    authority:
      loginURL + tenant + '/B2C_1_CSS_SUSI_POL', // Sign up and Sign In Policy
    forgotpasswordAuthority:
      loginURL + tenant + '/B2C_1_CSS_ResetPassword/', // Forget password policy name
    clientScope1: 'https://' + tenant + '/umdevapi/user_impersonation',
    postLogoutRedirectUri: 'https://app-diapasonumui-dev.azurewebsites.net/',
    encryption: {
      IV: "8080808080808080",
      Key: "8080808080808080"
    },
    version: version,
    tenant: tenant,
    authorityBase: loginURL,
    authorityURL: 'B2C_1_CSS_SUSI_POL',

    accessKeyId: 'AKIA5S4POE5IGLBREBEB',
    secretAccessKey: '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',
    region: 'us-east-2',
    blobUrl: 'https://carrierapps-dev.s3.us-east-2.amazonaws.com/logo/',
    blobSAS: '?sp=r&st=2021-08-03T09:49:37Z&se=2023-08-01T17:49:37Z&spr=https&sv=2020-08-04&sr=c&sig=4J9QRdWQYwXfA%2BsuMeZcKwHipQN3VFPxwM6lasQPfQQ%3D',
    loginConfig: devconfig //prodconfig //devconfig
    // {
    //   apiUrl: 'https://api.carrierappshub.com/', //'https://devapi.carrierappshub.com/',
    //   uiUrl: 'https://app-diapasonumui-dev.azurewebsites.net/',
    //   // uiUrl: http://localhost:4200/,
    //   // // userPoolId: "us-east-2_rjmskKvmB",
    //   // // identityPoolId: "us-east-2:d134dd1a-560b-46cf-a917-c9aae0684eea",
    //   // // clientId: "4pf6qvsk8b2rd9n1raj22ka18b",
    //   // // domain: "carrierum.auth.us-east-2.amazoncognito.com",
    //   postLogOutUri: 'https://app-diapasonumui-dev.azurewebsites.net',
    //   // redirectAppUri: 'https://authdev.carrierappshub.com',
    //   appType: 'CSS',
    //   authenticateOnly: true,
    // }
  },
}

export default appConfig;
