import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { ReactComponent as InternalUser } from '../../../Assests/Images/Logo.svg';
import { ReactComponent as HVACUser } from '../../../Assests/Images/hvacpartners.svg';
import { ReactComponent as ExternalUser } from '../../../Assests/Images/User.svg';
import { ReactComponent as Delete } from '../../../Assests/Images/Delete.svg';
import { TableBodyStyles, INVITATIONPENDING, DELETETOOLTIP,DELETEUSER,MOVEUSER, ON, OFF,INTERNALUSER, EXTERNALUSER,HVACUSER } from '../../../Common/Constants/constants';
import { intlShape, injectIntl } from "react-intl";
import { injectIntlTranslation } from "../../../Common/Utilities/utility";
import CheckIcon from '@material-ui/icons/Check';
import RemoveIcon from '@material-ui/icons/Remove';

const IOSSwitch = withStyles(theme => TableBodyStyles)(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

function TableBody(props) {
  const intl = props.intl;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="table-body">
      <div className="table-td">
        <span className="icon-left" title={injectIntlTranslation(intl, (props.userType == 0) ? INTERNALUSER : (props.userType == 1)? EXTERNALUSER : HVACUSER)}>
          {props.userType == 0 ? <InternalUser /> : props.userType == 1 ? <ExternalUser /> : <HVACUser />}
        </span>
        <span className="icon-left xs-hide">
          {((props.isActive == false) || (props.lastSigned == null || props.lastSigned == "")) ? <RemoveIcon/> : <CheckIcon style={{ color: "green" }}/>}
        </span>
        <div className="userInfo">
          <span className="userName">{props.name}</span>
          <div className="userEmail">{props.email}</div>
          <span className="userLastSigned xs-show">{props.lastSigned == null || props.lastSigned == "" ? <span className="invitationPending">({INVITATIONPENDING})</span> : props.lastSigned}</span>
        </div>
      </div>
      <div className="table-td xs-hide userLastSigned">
        {props.lastSigned == null || props.lastSigned == "" ? <span className="invitationPending">({INVITATIONPENDING})</span> : props.lastSigned}
      </div>
      <div className="table-td">
        <FormControlLabel
          control={
            <IOSSwitch
              checked={props.isActive}
              onChange={() => props.onhandleChangeActive(props)}
              value={props.isActive}
            />
          }
          label={props.isActive ? ON : OFF}
        />
      </div>
      <div className="table-td">
        {props.type==='group' ?
        <a href="#" onClick={handleClick}><MoreVertIcon /> </a> :
         <a href="#" className="removeitem" title={injectIntlTranslation(intl, DELETETOOLTIP)} onClick={() => props.onClickRemoveItem(props)}><Delete /> </a>}
       </div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}><a href="#" onClick={() => props.onClickMoveItem(props)}>{injectIntlTranslation(intl, MOVEUSER)}</a></MenuItem>
        <MenuItem onClick={handleClose}><a href="#" onClick={() => props.onClickRemoveItem(props)}>{injectIntlTranslation(intl, DELETEUSER)}</a></MenuItem>
      </Menu>
    </div>
  );
}


TableBody.propTypes = {
  intl: intlShape.isRequired
};

export default  injectIntl(TableBody);