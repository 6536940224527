import React from 'react';
import { Component } from 'react';
import * as Msal from 'msal';
import axiosInstance from '../src/Components/Shared/Interceptor/interceptor';
import Users from './Common/Services/Users';
import appConfig from './Environment/environments';
import * as constants from '../src/Common/Constants/constants'
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as jwt_decode from 'jwt-decode';
import { ReactAuthWrapper } from '@ccr-main/reactauthwrapper';
import { createSvg, noPermissionText } from './Common/Utilities/errorScreen';
class App extends Component {
  constructor(props) {
    super();
    this.state = {
      loading: true,
      users: []
    }
  }

  componentDidMount() {

  }
  authCallback = (data) => {
    if (data.appAccess) {
      this.props.history.push(constants.DASHBOARD);
    }
  }

  render() {
    return (
      <ReactAuthWrapper
        appConfig={appConfig.api.loginConfig}
        getPermissions={this.authCallback}
        errorScreenLogo={createSvg}
        noPermissionText={noPermissionText}
      >
        <div className="App">
        </div>
      </ReactAuthWrapper>
    );
  }
}

export default App;