import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
function APITreeView(props) {
  const [open, setOpen] = React.useState(false);
  const toggleNode = (node) => {
    if (node.id == "1") {
      setOpen(!open);
    }
    props.handleSelect(node);
  };
  const mapStructure = (nodes) => {
    if (nodes) {
      return nodes.map((node) => {
        if (node.children && node.children.length) {
          return (
            <List key={node.id}>
              <ListItem button onClick={() => toggleNode(node)} >
                <ListItemText primary={node.name} />
                {open ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {mapStructure(node.children)}
                </List>
              </Collapse>
            </List>
          )
        } else {
          return (
            <ListItem button onClick={() => toggleNode(node)} >
              <ListItemText primary={node.name} />
            </ListItem>
          )
        }
      });
    }
  };
  return (
    mapStructure(props.data)
  );
};
APITreeView.propTypes = {
  fullScreen: PropTypes.bool.isRequired
};
export default (APITreeView);