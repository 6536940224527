import React, {memo, useState} from "react";
import {ADMINISTRATORTEXT,MANAGE_DOMAINS} from '../../../Common/Constants/constants';
import CustomTabs from '../CustomTabs/CustomTabs';
import TabContainer from "../../Shared/TabContainer";
import Domains from "./Domains/Domains";

const Administrator = () => {
    const [adminTabvalue, setAdminTabValue] = useState(0);
    const tabLabels = [MANAGE_DOMAINS];
    const handleChange = (event, value) => setAdminTabValue(value)
    const showAdminTabContent = (selectedTab) => {
        switch(selectedTab) {
            case 0:
                    return  <TabContainer>
                                <Domains/>
                            </TabContainer>
                    break;
            default:
                    return null;
                    break;
        }
    }

    return (
    <>
        <p className="administratorTabContainer h-2-black-bold">
            {ADMINISTRATORTEXT}
        </p>
        <CustomTabs 
          handleChange={handleChange}
          tabLabels={tabLabels}
          variant="standard"
          defaultSelected={adminTabvalue} >
            {showAdminTabContent(adminTabvalue)}
        </CustomTabs>
    </>
)}

export default memo(Administrator);