import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SearchWithSuggestions from '../SearchWithSuggestions/SearchWithSuggestions';
import Typography from '@material-ui/core/Typography';
import { SendInvitationStyles, HVAC_EMAIL_SPECIAL_CHAR_ERROR, HVAC_EMAIL_PLACEHOLDER, HVAC_EMAIL, EMAIL, OR, EXTERNALEMAILREQUIREDERROR, EXTERNALEMAILINVALIDERROR, EMAILDISCLAIMER, EXTERNALEMAILPLACEHOLDER, INVITE } from '../../../Common/Constants/constants';
import { intlShape, injectIntl } from "react-intl";
import { injectIntlTranslation } from "../../../Common/Utilities/utility";
import {RoleCheck} from '../../Shared/Role/RoleCheck'

const styles = SendInvitationStyles;

class SendInvitation extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      externalUserEmail: '',
      hvacUser: '',
      name: '',
      nameError: '',
      userType: '',
      selectedUserInfo: '',
      selectedUserEmail: '',
      selectedUPN: '',
      selectedCarcglUserInfo: '',
      selectedCarcglUserEmail: '',
      selectedCarcglUPN: ''
    }

    this.onInviteButtonHandler = this.onInviteButtonHandler.bind(this);
    this.handleData = this.handleData.bind(this);
  }

  externalUserEmailChangeHandler = externalUserEmail => event => {
    this.setState({ [externalUserEmail]: event.target.value }, () => {
      this.validateEmail();
    });
  };

  validateEmail = () => {
    const { externalUserEmail } = this.state;
    this.setState({
      nameError:
        ((externalUserEmail == "") ? (this.props.loggedInUserType === 0) ? '' : EXTERNALEMAILREQUIREDERROR : (this.validateEmailRegex(externalUserEmail) ? EXTERNALEMAILINVALIDERROR : null))
    });
  }

  validateEmailRegex(email) {
    if (email != "") {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return !(re.test(email));
    } else {
      return null
    }
  }

  onInviteButtonHandler(e) {
    e.preventDefault();
    if (this.props.loggedInUserType === 2) {
      const { hvacUser } = this.state;
      const hvacObject = {
        groupId: this.props.Id,
        isManager: this.props.isManager,
        userType: 2, 
        email: "",
        name: "",
        upn: "",
        isCarCGL: true,
        hvacUserName: hvacUser
      };
      this.props.inviteHandleClose(hvacObject, false, true);
    }
    else {
      this.inviteHandler();
    }
  }

  inviteHandler = () => {
    let specifiedFieldsCount = 0;
    const externalUserEmailCheck = this.isNullOrUndefined(this.state.externalUserEmail);
    const selectedUserEmailCheck = this.isNullOrUndefined(this.state.selectedUserEmail);
    const selectedCarcglUserEmailCheck = this.isNullOrUndefined(this.state.selectedCarcglUserEmail);

    !externalUserEmailCheck && (specifiedFieldsCount = specifiedFieldsCount + 1);
    !selectedUserEmailCheck && (specifiedFieldsCount = specifiedFieldsCount + 1);
    !selectedCarcglUserEmailCheck && (specifiedFieldsCount = specifiedFieldsCount + 1);

    const isBothUserTypesSelected = (specifiedFieldsCount === 1) ? false : true;
    const email = ((externalUserEmailCheck) ? ((selectedUserEmailCheck) ? this.state.selectedCarcglUserEmail : this.state.selectedUserEmail) : this.state.externalUserEmail);
    const UPN = ((externalUserEmailCheck) ? ((selectedUserEmailCheck) ? this.state.selectedCarcglUPN : this.state.selectedUPN) : this.state.externalUserEmail);
    const name = (this.state.selectedUserInfo) ? this.state.selectedUserInfo : this.state.selectedCarcglUserInfo;
    const obj = {
       groupId: this.props.Id,
       isManager: this.props.isManager,
       userType: (externalUserEmailCheck ? 0 : 1),
       email: email,
       name: name,
       UPN: UPN
    }
    !selectedCarcglUserEmailCheck && (obj.isCarCGL = true)
    if (obj.email !== "") {
       const updatedObj = JSON.stringify(obj);
       this.props.inviteHandleClose(updatedObj, isBothUserTypesSelected);
    }
  }

  handleData(isCarCgl, data, email, userPrincipalName) {
    if (isCarCgl) {
      this.setState({
        selectedCarcglUserInfo: data,
        selectedCarcglUserEmail: email,
        selectedCarcglUPN: userPrincipalName
      });
    }
    else {
      this.setState({
        selectedUserInfo: data,
        selectedUserEmail: email,
        selectedUPN: userPrincipalName
      });
    }
  }

  isNullOrUndefined(data) {
    return ((data === '' || data === " " || data === null || data === "null" || data === undefined) ? true :false);
  }

  createInternalUserField(classes, callback, isCarCgl=false, autoFocus=false) {
    return (
      <div className={classes.externalUserEmailTextField}>
        <div className={classes.internalUserEmailTextField}>
          <SearchWithSuggestions autoFocus={autoFocus} isCarCgl={isCarCgl} getSelectedUserInfo={callback}
          />
        </div>
        {RoleCheck() ? <p className={classes.internalUserORText}>
          {OR}
        </p> : ""}
      </div> 
    )
  }

  hvacFieldValidation = ({ target: { value }}) => {
    const { hvacUser } = this.state;
    const hvacRegEx = /^(\d|\w)+$/;
    const hvacRegExTest = !(hvacRegEx.test(value));
    this.setState({
      hvacUser: value,
      nameError:
        ((value == "") ?  EXTERNALEMAILREQUIREDERROR : (hvacRegExTest ? HVAC_EMAIL_SPECIAL_CHAR_ERROR : null))
    });
  }

  createUserField = (classes, intl, userType) => {
    const id = userType === 2 ? "hvacUserEmail" : "externalUserEmail";
    const label = userType === 2 ? HVAC_EMAIL : EMAIL;
    const placeholder = userType === 2 ? HVAC_EMAIL_PLACEHOLDER : EXTERNALEMAILPLACEHOLDER;
    const value = userType === 2 ? this.state.hvacUser : this.state.externalUserEmail;
    const onChangeHandler = userType === 2 ? this.hvacFieldValidation : this.externalUserEmailChangeHandler('externalUserEmail');
    const userProps = {
      value: value,
      id: id,
      label: label,
      placeholder: injectIntlTranslation(intl, placeholder),
      onChange: onChangeHandler
    };

    return (
      <TextField
          required
          InputProps={{
            classes: {
              root: classes.inputRoot,
              input: classes.inputStyles,
              focused: `${this.state.nameError ? classes.invalidTextField : classes.inputFocused}`,
              underline: `${this.state.nameError ? classes.invalidTextField : classes.inputUnderline}`
            }
          }}
          InputLabelProps={{
            classes: {
              root: classes.labelRoot,
              focused: classes.labelFocused
            },
            required: false
          }}
          className={classes.externalUserEmailTextField}
          margin="normal"
          {...userProps} />
    )
  }

  render() {
    const { classes } = this.props;
    const intl = this.props.intl;
    return (
      <div className={classes.userEmailInfo}>
        {
          (this.props.loggedInUserType === 0) &&
            <>
              <Typography classes={{ root: classes.disclaimer }}>
                {EMAILDISCLAIMER}
              </Typography>
              {this.createInternalUserField(classes, this.handleData, true, true)}
            </>
        }
        { RoleCheck() ? this.createUserField(classes, intl, this.props.loggedInUserType) : "" }
        <div className={classes.errorText}>{this.state.nameError}</div>

        {
          (!this.state.isBothUserTypesSelected && this.state.nameError === null || (this.state.selectedUserInfo) || (this.state.selectedCarcglUserInfo)) &&
            <div className={classes.saveButton}>
              <Button
                type="submit"
                variant="contained"
                disableRipple
                className={classNames(classes.margin, classes.updatebutton)}
                onClick={this.onInviteButtonHandler}
              >
                {INVITE}
              </Button>
            </div>
        }
      </div>
    )
  }
}

SendInvitation.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired
};

export default injectIntl(withStyles(styles, { withTheme: true })(SendInvitation));
