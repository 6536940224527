import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { ManageGroupStyles, GROUPSTEXT, DEVELOPERTAB, ADMINSTRATORS, PERMISSIONSTAB } from '../../../Common/Constants/constants';
import '../../../Assests/Styles/Styles.scss';
import '../../Groups/manageUsers/manageUsers.scss';
import DeveloperTab from './DeveloperTab';
import AdminTab from './AdminTab';
import GroupsTab from './GroupsTab';
import PermissionsTab from './PermissionsTab';
import TabContainer from "../../Shared/TabContainer";
import CustomTabs from "../../Shared/CustomTabs/CustomTabs";

class EditAppTabs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 0,
      tabLabels: [GROUPSTEXT,DEVELOPERTAB,ADMINSTRATORS,PERMISSIONSTAB]
    };
  }

  appsTabs = (selectedTab) => {
    const {appId,appName} = this.props;
    switch (selectedTab) {
      case 0: return <div className="developerFieldsContainer"><GroupsTab Id={appId} /></div>
        break;
      case 1: return <TabContainer><DeveloperTab /></TabContainer>
        break;
      case 2: return <div className="developerFieldsContainer"><AdminTab Id={appId} /></div>
        break;
      case 3: return <div className="developerFieldsContainer"><PermissionsTab Id={appId} appName={appName} /></div>
        break;
      default: return null;
    }
  }

  handleChange = (event, value) =>
    this.setState({
      value
    });

  render() {
    const {classes} = this.props;
    const {value,tabLabels} = this.state;
    return (
      <div className={classes.root}>
        <CustomTabs 
          showButton={false}
          handleChange={this.handleChange}
          tabLabels={tabLabels}
          defaultSelected={value} />
        {this.appsTabs(value)}
      </div>
    );
  }
}

EditAppTabs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(ManageGroupStyles)(EditAppTabs);
