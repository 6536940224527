import React, { memo, useEffect, useState } from "react";
import { ADD_DOMAIN_API_ERROR,ADD_DOMAIN_API_SUCCESS,ADD_DOMAIN_ERROR,FIELDREQ,
    MANAGE_DOMAINS_FAIL,LOADINGTEXT,WHITE_LIST_NOTE,ADD_DOMAIN,GET_ALL_DOMAINS,
    SAVE_DOMAIN, DOMAIN_ALREADY_ADDED } from '../../../../Common/Constants/constants';
import { intlShape, injectIntl } from "react-intl";
import { DomainData } from "./DomainsDataHandler";
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import { CustomButton } from "../../CustomButton/CustomButton";
import DomainsStyles from "./DomainsStyles";
import ToastMessages from '../../Toast/ToastMessages';
import {apiGet,apiPost} from '../../Interceptor/apiService';

const Domains = () => {
    const [domainState, setDomainState] = useState({
        apiFetch: true,
        loading: true,
        domainData: DomainData,
        addedDomains:[],
        deletedDomains:[],
        showstatusMessage: false,
        message: "",
        messageVariant: ""
    });
    const {apiFetch,loading,domainData,showstatusMessage,message,messageVariant} = domainState;
    const { domainContainer, domainsChipContainer, domainsChip,
        domainInputContainer, domainInput, domainErrorMessage } = DomainsStyles();

    useEffect(() => {
        getDomainsData();
    }, [])

    const getDomainsData = async() => {
        const {data} = await apiGet(GET_ALL_DOMAINS, getDomainsfailure);
        getDomainsSuccess(data);
    }

    const getDomainsSuccess = (domainsData) => {
        const { whiteListDomains, blackListDomains } = domainsData;
        let newDomainData = [...domainData];
        newDomainData[0].content = whiteListDomains;
        newDomainData[1].content = blackListDomains;
        const newDomianState = {
            domainData: newDomainData,
            loading: false,
            apiFetch: true
        }
        setDomainState({...domainState, ...newDomianState})
    }

    const getDomainsfailure = () => setDomainState({...domainState, loading: false, apiFetch: false})

    const onAddDeleteDomainClick = (index, isDelete=false, chipIndex) => {
        const isWhiteList = index ? false : true;
        let apiObj = {"AddedDomains" : [],"DeletedDomains":[]};
        if(isDelete) {
            const textFieldValue = (domainData[index].content[chipIndex] || "");
            const domain = [{"DomainName":textFieldValue,"isWhiteList": isWhiteList}]
            apiObj.DeletedDomains = domain;
            saveDomainAPI(SAVE_DOMAIN,apiObj,textFieldValue,isDelete,index,chipIndex)
        }
        else {
            const textFieldValue = (domainData[index].textFieldValue || "");
            const domain = [{"DomainName":textFieldValue,"isWhiteList": isWhiteList}]
            if(!textFieldValidation(textFieldValue, index, true)) {            
                apiObj.AddedDomains = domain;
                saveDomainAPI(SAVE_DOMAIN,apiObj,textFieldValue,isDelete,index,chipIndex)
            }            
        }
    }

    const saveDomainAPI = async(apiUrl,apiObj,textFieldValue="",isDelete=false,index=0,chipIndex=0) => {
        const apiInput = JSON.stringify(apiObj);
        const {data} = await apiPost(apiUrl, apiInput, apiFailure);
        if(data) {
            apiSuccess(isDelete,index,chipIndex,textFieldValue);
        }
        else {
            apiFailure();
        }
    }

    const apiSuccess = (isDelete,index,chipIndex,textFieldValue) => {
        const newDomainState = {
            showstatusMessage: true,
            message: ADD_DOMAIN_API_SUCCESS,
            messageVariant: "success"
        }
        let newdomainData = [...domainData];
        if(isDelete) {
            newdomainData[index].textFieldValue = "";
            newdomainData[index].content.splice(chipIndex,1);
            newDomainState.domainData = newdomainData;
        }
        else {
            newdomainData[index].textFieldValue = "";
            newdomainData[index].content.push(textFieldValue)
            newDomainState.domainData = newdomainData;
        }
        setDomainState({...domainState, ...newDomainState})
    }

    const apiFailure = () => {
        const newDomainState = {
            showstatusMessage: true,
            message: ADD_DOMAIN_API_ERROR,
            messageVariant: "error"
        }
        setDomainState({...domainState, ...newDomainState})
    }

    const renderDomains = () => {
        return (
            apiFetch ?
                <>
                    {WHITE_LIST_NOTE}
                    {domainsList()}
                </>
                :
                MANAGE_DOMAINS_FAIL
        )
    }

    const onTextFieldChange = (event, index) => {
        const {target: {value}} = event;
        textFieldValidation(value, index);
    }

    const textFieldValidation = (textFieldValue, index, returnValidation=false) => {
        const spaceCheckRegEx = /\s/;
        let showError = true;
        let errorMessage = "";
        let newDomainData = [...domainData];
        if (!textFieldValue) {
            errorMessage = FIELDREQ;
        }
        else if (spaceCheckRegEx.test(textFieldValue)) {
            errorMessage = ADD_DOMAIN_ERROR;
        }
        else if (newDomainData[index].content.indexOf(textFieldValue) > -1) {
            errorMessage = DOMAIN_ALREADY_ADDED;
        }
        else {
            showError = false;
        }
        newDomainData[index].textFieldValue = textFieldValue;
        newDomainData[index].errorMessage = errorMessage;
        setDomainState({...domainState, domainData: newDomainData})
        if(returnValidation) {
            return showError;
        }
    }

    const handleToast = () => setDomainState({...domainState, showstatusMessage: false})

    const domainsList = () => {
        if (!!domainData.length) {
            return domainData.map((contentItem, index) => {
                const { id = index, label = "", textFieldValue,errorMessage,content = [], helpContents = [],enableButton=false } = contentItem;
                const chipDomainType = index;
                return (
                    <div id={id}>
                        <h3>{label}</h3>
                        <div className={domainContainer}>
                            <TextField
                                className={domainInputContainer}
                                inputProps={{
                                    className: domainInput,
                                }}
                                onChange={(e) => onTextFieldChange(e,index)}
                                disabled={false}
                                fullWidth={true}
                                value={textFieldValue}
                                variant="outlined" />
                            <CustomButton  isButtonDisabled={enableButton} onClick={() => onAddDeleteDomainClick(index)} buttonLabel={ADD_DOMAIN} />
                        </div>
                        <span className={domainErrorMessage}>{errorMessage}</span>
                        <div className={domainsChipContainer}>
                            {!!content && content.map((contentItem, index) =>
                                <Chip
                                    id={index}
                                    className={domainsChip}
                                    label={contentItem}
                                    onDelete={() => onAddDeleteDomainClick(chipDomainType, true, index)}
                                />)
                            }
                        </div>
                        <div>
                            {!!helpContents && helpContents}
                        </div>
                    </div>
                )
            })
        }
        return null;
    }

    return (
        <>
            {loading ?
                <span>{LOADINGTEXT}</span>
                :
                <>
                    <ToastMessages
                        statusMessage={showstatusMessage}
                        message={message}
                        variant={messageVariant}
                        toastHPosition={"center"}
                        toastVPosition={"top"}
                        close={handleToast} />
                    {renderDomains()}
                </>
            }
        </>
    )
}

Domains.propTypes = {
    intl: intlShape.isRequired
};

export default injectIntl(memo(Domains));