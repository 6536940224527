import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { ManageGroupStyles,INSIGHTS,UNABLE_TO_ADD_USER,HVAC_INVALID_MESSAGE,HVAC_INACTIVE_MESSAGE,
  ADDADMIN,ADDUSER,ADMINSTRATORS,SEND_HVAC_INVITATION_GROUP,SENDINVITATIONGROUP,USERSTEXT, 
  SPECIFYONLYONEFIELDERROR,USERADDEDTOGROUP,USERALREADYEXISTERROR,INVALID_DOMAIN_ERROR } from '../../../Common/Constants/constants';
import AdminUsersGrid from '../../Shared/AdminUsersGrid/AdminUsersGrid';
import UserInvite from '../../Shared/userInvite/userInvite';
import Insights from '../../Shared/Insights/Insights';
import CustomTabs from '../../Shared/CustomTabs/CustomTabs';
import '../../../Assests/Styles/Styles.scss';
import axiosInstance from '../../Shared/Interceptor/interceptor';
import appConfig from '../../../Environment/environments';
import ToastMessages from '../../Shared/Toast/ToastMessages';
import './manageUsers.scss';
import {injectIntlTranslation,getDataTranslated} from '../../../Common/Utilities/utility';
import { intlShape, injectIntl } from "react-intl";
import {getAllUserAdminData,getAllAdminData} from "./manageUsersHelper";
import TabContainer from "../../Shared/TabContainer";

class ManageUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      label: ADDADMIN,
      tabLabels: [ADMINSTRATORS, USERSTEXT, INSIGHTS],
      right: false,
      adminData: [],
      initialRender: false,
      userAdminData: [],
      userInitialRender: false,
      statusMessage: false,
      message: "",
      toastVPosition: 'top',
      toastHPosition: 'center',
      messageVariant: '',
    };
  }

  componentDidMount() {
    getAllAdminData(this.props.Id, this.getAllAdminDataSuccess);
    getAllUserAdminData(this.props.Id, this.getAllUserAdminDataSuccess);
  }

  getAllAdminDataSuccess = ({data}) =>
    this.setState({
      adminData: getDataTranslated(data.admins),
      initialRender: true
    })

  getAllUserAdminDataSuccess = ({data}) =>
    this.setState({
      userAdminData: getDataTranslated(data),
      userInitialRender: true
    })

  onInviteButtonHandler(updatedObj, isBothUserTypesSelected=false, isHvac=false) {
    if (isBothUserTypesSelected) {
      this.setState({
        statusMessage: true,
        message: SPECIFYONLYONEFIELDERROR,
        messageVariant: "error"
      })
    }
    else {
      const url = isHvac ? SEND_HVAC_INVITATION_GROUP : SENDINVITATIONGROUP;
      axiosInstance.post(appConfig.api.development + url, updatedObj, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      })
        .then(res => {
          if (res) {
            this.setState({
              statusMessage: true,
              message: USERADDEDTOGROUP,
              messageVariant: "success",
              right: false
            })
          }
          if ( this.state.label === ADDADMIN)
          {
            getAllAdminData(this.props.Id, this.getAllAdminDataSuccess);
          }
          else {
            getAllUserAdminData(this.props.Id, this.getAllUserAdminDataSuccess);
          }
          this.openInviteUserDrawer('right', this.state.right);
          this.props.onContentChange();
        })
        .catch(error => {
          if (error.response) {
            let errorMessage = USERALREADYEXISTERROR;
            if (error.response.data.message.indexOf("is inactive.") > -1) {
              errorMessage = (injectIntlTranslation(this.props.intl, HVAC_INACTIVE_MESSAGE)).replace('_HVACUSER_', "\"" + updatedObj.hvacUserName + "\"");
            }
            else if (error.response.data.message.indexOf("is invalid.") > -1) {
              errorMessage = (injectIntlTranslation(this.props.intl, HVAC_INVALID_MESSAGE)).replace('_HVACUSER_', "\"" + updatedObj.hvacUserName + "\"");
            }
            else if (error.response.data.message.indexOf("Invalid domain") > -1) {
              errorMessage = INVALID_DOMAIN_ERROR
            }
            this.setState({
              statusMessage: true,
              message: errorMessage,
              messageVariant: "error"
            })
          }
          else {
            this.setState({
              statusMessage: true,
              message: UNABLE_TO_ADD_USER,
              messageVariant: "error"
            })
          }
        })
    }
  }

  handleChange = (event, value) =>
    this.setState({
      value,
      label: (value === 0 ? ADDADMIN : ADDUSER)
    });

  handleToast(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      statusMessage: false
    });
  }

  openInviteUserDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    this.setState({ [side]: open, statusMessage: false });
  };

  setStatusMessage = (value) => {
    this.setState({ statusMessage: value});
  }

  showTabContent = (selectedTab) => {
    const {Id, groupName} = this.props;
    const tabProps = {
      onContentChange: this.props.onContentChange,
      groupId: Id,
      initialRender: this.state.userInitialRender,
      type:"group"
    };
    switch(selectedTab) {
      case 0:
              return  <TabContainer>
                        <AdminUsersGrid adminData={this.state.adminData} gridType={0} {...tabProps} />
                      </TabContainer>
              break;
      case 1:
              return  <TabContainer>
                        <AdminUsersGrid adminData={this.state.userAdminData} gridType={1} {...tabProps} />
                      </TabContainer>
              break;
      case 2:
              return <Insights groupName={groupName} groupId={Id} />;
              break;
      default:
              return null;
              break;
    }
  }

  render() {
    const {root} = this.props.classes;
    const {value,tabLabels,label,right} = this.state;
    return (
      <div className={root}>
        <CustomTabs 
          showButton={(value!==2)?true:false}
          buttonLabel={label}
          onButtonClick={this.openInviteUserDrawer('right', true)}
          handleChange={this.handleChange}
          tabLabels={tabLabels}
          defaultSelected={value} >
            {this.showTabContent(value)}
        </CustomTabs>
        <UserInvite
          eventHandle={this.onInviteButtonHandler.bind(this)}
          historyid={this.props.historyid}
          open={this.state.right}
          onClose={this.openInviteUserDrawer('right', false)}
          ID={this.props.Id}
          loggedInUserType={this.props.loggedInUserType}
          isManager={value === 0 ? true : false}
          statusMessage={this.state.statusMessage}
          message={this.state.message}
          messageVariant={this.state.messageVariant}
          setStatusMessage={this.setStatusMessage}
        />
        {!right && <ToastMessages
          statusMessage={this.state.statusMessage}
          message={this.state.message}
          variant={this.state.messageVariant}
          toastHPosition={this.state.toastHPosition}
          toastVPosition={this.state.toastVPosition}
          close={this.handleToast.bind(this)}
        />}
      </div>
    );
  }
}

ManageUsers.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired
};

export default injectIntl(withStyles(ManageGroupStyles)(ManageUsers));