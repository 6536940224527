import { translation } from '../Utilities/utility';

export const GET_CARCGL_INTERNAL_USER_NAME = "/api/User/GetInternalUsersByName?name=";
export const APPURL = "api/app/all";
export const GETGROUPAPPDETAILS = "api/GroupApps/GetGroupAppDetails"
export const GROUPADD = translation("GROUPADD");
export const PARENTGROUPCHANGE = translation("PARENTGROUPCHANGE");
export const USERMOVEDSUCCESS = translation("USERMOVEDSUCCESS");
export const USERALREADYEXISTERROR = translation("USERALREADYEXISTERROR");
export const GROUPDEL = "GROUPDEL";
export const CHILDSUBGROUP = '/api/group/childsubgroups';
export const GROUPNAMES = '/api/users/GetGroups'
export const GROUPAPI = '/api/group';
export const PARENTGROUPCHANGEAPI = '/api/group/UpdateParentGroup';
export const MOVEUSERAPI = '/api/groupUser/MoveCSSUserToGroup';
export const GET_ALL_DOMAINS = '/api/Domain/GetAllDomains';
export const SAVE_DOMAIN = '/api/Domain/SaveDomain';
export const APPDELETE = '/api/app/Id?AppId=';
export const FIELDREQ = translation('FIELDREQ');
export const RQRGROUP = translation('RQRGROUP');
export const GROUPLENGTH = translation('GROUPLENGTH');
export const GROUPLENGTH50 = translation('GROUPLENGTH50');
export const DEVURLDISCLAIMER = translation('DEVURLDISCLAIMER');
export const CLOSE = translation('CLOSE');
export const APITREE = translation('APITREE');
export const ENTER = 'Enter';
export const SEARCHERROR = 'No Matches Found';
export const NODEONENAME = 'UM Integration API';
export const NODEONEDESC = 'User Management being the platform for many Carrier applications access management, the following documentation provides insights on the required APIs that have to be integrated in the application before registering to User Management.Please refer the below code snippet to encrypt parameter.';
export const NODETWONAME = 'User Profile and Permissions API';
export const NODETWODESC = 'This API is to check if user is exist in User management and create new user if not found and also to return permissions of the user';
export const NODETHREENAME = 'Fetch Users based on Permissions API';
export const NODETHREEDESC = 'This API is to fetch the list of users based on the permissions';
export const NODEFOURNAME = 'User Details API';
export const NODEFOURDESC = 'This API is used to get the User Details by using User Id';
export const NODEFIVENAME = 'Group Details API';
export const NODEFIVEDESC = 'This API is used to get the Group Details by using Group Id';
export const NODESIXNAME = 'Last Signin Update API';
export const NODESIXDESC = 'This API is to update user last signin date time in User management';
export const COPIED = 'Copied';
export const COPYFAILED = 'Failed to Copy';
export const REACT = `export const encryptData = (data) => {
  let key = CryptoJS.enc.Utf8.parse(encryption.Key);
  let iv = {
      keySize: 128 / 8,
      iv: CryptoJS.enc.Utf8.parse(encryption.IV),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
  };
  return encodeURIComponent(CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(data), key, iv));
}

encryption: {
      IV: "8080808080808080",
      Key: "8080808080808080"
}`;
export const CSHARP = `public static string EncryptString(string plainText)
{
	try
	{
		byte[] encrypted;
		//Create a RijndaelManaged object with the specified key and IV.
		using(var rijAlg = new RijndaelManaged())
		{
			rijAlg.Mode = CipherMode.CBC;
			rijAlg.Padding = PaddingMode.PKCS7;
			rijAlg.FeedbackSize = 128;
			rijAlg.Key = key;
			rijAlg.IV= iv;
			
			// Create a decrytor to perform the stream transform.
			var encryptor = rijAlg.CreateEncryptor(rijAlg.Key, rijAlg.IV);
			
			// Create the streams used for encryption.
			using (var msEncrypt = new MemoryStream())
			{
				using (var csEncrypt = new CryptoStream(msEncrypt, encryptor, CryptoStreamMode.Write))
				{
					using (var swEncrypt = new StreamWriter(csEncrypt))
					{
						//Write all the data to the stream.
						swEncrypt.Write(plainText);
					}
					encrypted = msEncrypt.ToArray();
				}
			}
		}
    return System.Net.WebUtility.UrlEncode(Convert.ToBase64String(encrypted)
          .Replace(ConstantHelper.SlashChar, ConstantHelper.SafeChar, StringComparison.InvariantCulture));
	}
	catch
	{
		throw new UnoBaseException("Input parameters are not valid.");
	}
}`;
export const AUTHTOKEN = 'Bearer {{Token}}';
export const GETVERB = 'GET';
export const POSTVERB = 'POST';
export const DESCRIPTION = translation('DESCRIPTION');
export const GROUPS = '/Groups';
export const USERS = '/Users';
export const ADMINISTRATOR = '/Administrator';
export const DASHBOARD = '/dashboard';
export const MIGRATIONWARNING = '/migrationError';
export const INVALIDHVACUSERWARNING = '/invalidHvacError';
export const ADDEDITAPP = '/EditApp';
export const DASHBOARDTEXT = translation("DASHBOARD");
export const GROUPTEXT = translation("GROUPS");
export const ADMINISTRATORTEXT = translation("ADMINISTRATORTEXT");
export const WHITELIST = translation("WHITELIST");
export const INVALID_DOMAIN_ERROR = translation("INVALID_DOMAIN_ERROR");
export const MANAGE_DOMAINS = translation("MANAGE_DOMAINS");
export const ADD_DOMAIN = translation("ADD_DOMAIN");
export const ADD_DOMAIN_ERROR = translation("ADD_DOMAIN_ERROR");
export const DOMAIN_ALREADY_ADDED = translation("DOMAIN_ALREADY_ADDED");
export const ADD_DOMAIN_API_SUCCESS = translation("ADD_DOMAIN_API_SUCCESS");
export const ADD_DOMAIN_API_ERROR = translation("ADD_DOMAIN_API_ERROR");
export const MANAGE_DOMAINS_FAIL = translation("MANAGE_DOMAINS_FAIL");
export const REFRESH = translation("REFRESH");
export const FILTER_SELECTED_GROUP = translation("FILTER_SELECTED_GROUP");
export const FILTER_SELECTED_GROUP_CHILD = translation("FILTER_SELECTED_GROUP_CHILD");
export const ALL_PERMISSIONS = translation("ALL_PERMISSIONS");
export const FILTER_GROUPS = translation("FILTER_GROUPS");
export const FILTER_PERMISSIONS = translation("FILTER_PERMISSIONS");
export const WHITE_LIST_NOTE = translation("WHITE_LIST_NOTE");
export const BLACKLIST = translation("BLACKLIST");
export const WHITELIST_PARA_1 = translation("WHITELIST_PARA_1");
export const WHITELIST_PARA_2 = translation("WHITELIST_PARA_2");
export const WHITELIST_PARA_3 = translation("WHITELIST_PARA_3");
export const WHITELIST_PARA_4 = translation("WHITELIST_PARA_4");
export const EDITBYID = '/api/group/id?Id=';
export const GROUPSEARCH = '/api/group/Search';
export const ADDADMIN = translation("ADDADMIN");
export const GROUPEDIT = translation('GROUPEDIT');
export const ADDUSER = translation("ADDUSER");
export const APPADMINTEXT = translation("APPADMINTEXT");
export const APPGROUPTEXT = translation("APPGROUPTEXT");
export const GROUPBYID = "/api/groupUser/GetAllSubGroups?parentGroupId=";
export const GROUPALL = "/api/groupUser/GetAllGroups";
export const ISEXIST = "/api/users/exists?useremail=";
export const LASTSIGNEDIN = "/api/users/UpdateUserLastSignedIn";
export const CREATEUSER = "/api/users/create";
export const ROOTSETTINGS = "/api/GroupAppSettings?id=";
export const SAVESETTINGS = "/api/GroupAppSettings";
export const GROUPAPPSETTINGSDETAILS = "/api/GroupAppSettings/GroupAppSettingDetails";
export const GROUPAPPCHILDSETTINGSDETAILS = "/api/GroupAppSettings/GroupAppChildSettingDetails";
export const CALLBACK = "/authcallback";
export const GROUPUSER = "/api/groupUser/groupusers?groupId=";
export const DELETEGROUP = translation("DELETEGROUP");
export const CHANGEPARENTGROUP = "CHANGEPARENTGROUP";
export const CHANGE_PARENT_GROUP = "CHANGE_PARENT_GROUP";
export const SELECT_GROUP = "SELECT_GROUP";
export const DELETEGRPDIALOG = translation("DELETEGRPDIALOG");
export const APPSGROUPSNOACCESSTEXT = translation("APPSGROUPSNOACCESSTEXT");
export const CONTACTADMINTEXT = translation("CONTACTADMINTEXT");
export const USERDETAIL = "/api/users/GetUserDetails?useremail=";
export const NOADMINDATA = translation("NOADMINDATA");
export const ADMINDATA = "/api/groupUser/groupadmins?groupId=";
export const APPADMINDATA = "/api/AppUsers/AppAdmins?appId=";
export const GETAPPSGROUPDATA = "/api/GroupApps/childgroups?appId=";
export const GROUPUSERAPI = '/api/groupUser?GroupUserId=';
export const APPUSERAPI = '/api/AppUsers?appUserId=';
export const USERACTIVE = "/api/groupUser/ActivateDeactivate?groupUserId=";
export const APPUSERACTIVE = "/api/AppUsers/ActivateDeactivate?appUserId=";
export const DELETEADMIN = translation("DELETEADMIN");
export const LOADINGTEXT = translation("LOADINGTEXT");
export const DELETETEXT = translation("DELETETEXT");
export const UPDATETEXT = translation("UPDATETEXT");
export const DELETETOOLTIP = "DELETETEXT";
export const CONTINUETEXT = translation("CONTINUETEXT");
export const CANCELTEXT = translation("CANCELTEXT");
export const DELETEUSER = translation("DELETEUSER");
export const MOVEUSER = translation("MOVEUSER");
export const NOUSERTEXT = translation("NOUSERTEXT");
export const SAVETEXT = translation("SAVETEXT");
export const ADDPERMISSIONSTEXT = translation("ADDPERMISSIONSTEXT");
export const CREATEPERMISSION = "/api/setting";
export const DELETEROOTPERMISSION = "/api/AppSettings/DeleteAppSettings?appId=";
export const DELETECHILDPERMISSION = "/api/Setting?SettingId=";
export const FETCHPERMISSIONS = "/api/AppSettings/GetAppPermissions?appId=";
export const ADDCHILDPERMISSIONS = translation("ADDCHILDPERMISSIONS");
export const PERMISSIONSSAVEFAIL = translation("PERMISSIONSSAVEFAIL");
export const PERMISSIONSUNIQUEFAIL = translation("PERMISSIONSUNIQUEFAIL");
export const PERMISSIONSREMOVEFAIL = translation("PERMISSIONSREMOVEFAIL");
export const PERMISSIONSSAVESUCCESS = translation("PERMISSIONSSAVESUCCESS");
export const PERMISSIONSREMOVESUCCESS = translation("PERMISSIONSREMOVESUCCESS");
export const PERMISSIONSFETCHFAIL = translation("PERMISSIONSFETCHFAIL");
export const NOPERMISSIONSTEXT = translation("NOPERMISSIONSTEXT");
export const CONFIRMATIONTEXT = translation("CONFIRMATIONTEXT");
export const ROLESTEXT = translation("ROLESTEXT");
export const REQUIREDFIELDDISCLAIMERTEXT = translation("REQUIREDFIELDDISCLAIMERTEXT");
export const EDITGROUPTEXT = translation("EDITGROUPTEXT");
export const UNSAVEDCHANGESHEADERTEXT = translation("UNSAVEDCHANGESHEADERTEXT");
export const UNSAVEDCHANGESDESCRIPTION = translation("UNSAVEDCHANGESDESCRIPTION");
export const DELETEADMINDIALOG = translation('DELETEADMINDIALOG');
export const DELETEUSERDIALOG = translation("DELETEUSERDIALOG");
export const EXTERNALEMAILREQUIREDERROR = translation('EXTERNALEMAILREQUIREDERROR');
export const EXTERNALEMAILINVALIDERROR = translation("EXTERNALEMAILINVALIDERROR");
export const EMAILDISCLAIMER = translation("EMAILDISCLAIMER");
export const EXTERNALEMAILPLACEHOLDER = "EXTERNALEMAILPLACEHOLDER";
export const GROUPROLES = "/api/GroupRoles/";
export const GETGROUPROLES = "/api/GroupRoles?id=";
export const GROUPROLESERROR = translation("GROUPROLESERROR");
export const GROUPROLESLOADING = translation("GROUPROLESLOADING");
export const GROUPROLESNOROLES = translation("GROUPROLESNOROLES");
export const SETTINGSLABEL = translation("SETTINGSLABEL");
export const LOADINGSETTINGSTEXT = translation("LOADINGSETTINGSTEXT");
export const NOSETTINGSTEXT = translation("NOSETTINGSTEXT");
export const SETTINGSSAVETEXT = translation("SETTINGSSAVETEXT");
export const SETTINGSERRORTEXT = translation("SETTINGSERRORTEXT");
export const USERALREADYEXISTSERROR = translation("USERALREADYEXISTSERROR");
export const CANNOTSELECTCHILDASPARENTGROUPERROR = translation("CANNOTSELECTCHILDASPARENTGROUPERROR");
export const SAMEPARENTSELECT = translation("SAMEPARENTSELECT");
export const SAMEGROUPASPARENTSELECT = translation("SAMEGROUPASPARENTSELECT");
export const ADMINGRIDLABELTEXT = translation("ADMINGRIDLABELTEXT");
export const USERSGRIDLABELTEXT = translation("USERSGRIDLABELTEXT");
export const LASTSIGNEDINTEXT = translation("LASTSIGNEDINTEXT");
export const APPSLABELTEXT = translation("APPSLABELTEXT");
export const APPSLOADINGTEXT = translation("APPSLOADINGTEXT");
export const ADDAPP = translation("ADDAPP");
export const DELETEAPPHEADER = translation("DELETEAPPHEADER");
export const DELETEAPPCONTENT = translation("DELETEAPPCONTENT");
export const NEWAPP = translation("NEWAPP");
export const EDITAPP = translation("EDITAPP");
export const APPNAME = translation("APPNAME");
export const APPSGROUPSAVESUCCESS = translation("APPSGROUPSAVESUCCESS");
export const APPSGROUPSAVEFAIL = translation("APPSGROUPSAVEFAIL");
export const IMAGE = translation("IMAGE");
export const UPLOAD = translation("UPLOAD");
export const PREVIEW = translation("PREVIEW");
export const IMGRESERROR = translation("IMGRESERROR");
export const IMGTYPEERROR = translation("IMGTYPEERROR");
export const IMGSIZEERROR = translation("IMGSIZEERROR");
export const APPSAVEERROR = translation("APPSAVEERROR");
export const APPNOCHANGES = translation("APPNOCHANGES");
export const APPCREATESUCCESS = translation("APPCREATESUCCESS");
export const APPUPDATESUCCESS = translation("APPUPDATESUCCESS");
export const APPALREADYEXISTERROR = translation("APPALREADYEXISTERROR");
export const APPCREATEFAIL = translation("APPCREATEFAIL");
export const INVALIDURL = translation("INVALIDURL");
export const GETAPPDETAILSFAIL = translation("GETAPPDETAILSFAIL");
export const CREATEAPP = "/api/app";
export const GETAPPDETAILS = "/api/app/id?appId=";
export const IMGUPLOADDISCLAIMER = translation("IMGUPLOADDISCLAIMER");
export const URL = translation("URL");
export const DEVURL = translation("DEVURL");
export const APPPERMISSIONNAME = translation("APPPERMISSIONNAME");
export const REDIRECTURL = translation("REDIRECTURL");
export const APPSNOTFOUNDTEXT = translation("APPSNOTFOUNDTEXT");
export const MIGRATIONWARNINGTEXT = translation("MIGRATIONWARNINGTEXT");
export const INVALIDHVACUSERTEXT = translation("INVALIDHVACUSERTEXT");
export const MIGRATIONWARNINGLOGOUTTEXT = translation("MIGRATIONWARNINGLOGOUTTEXT");
export const LOGIN = translation("LOGIN");
export const SEARCHSUGGESTIONSERROR = translation("SEARCHSUGGESTIONSERROR");
export const SEARCHSUGGESTIONLABEL = translation("SEARCHSUGGESTIONLABEL");
export const LOGOUT = translation("LOGOUT");
export const DELETEACCOUNT = translation("DELETEACCOUNT");
export const UPDATEEMAIL = translation("UPDATEEMAIL");
export const UIDOCUMENTATION = translation("UIDOCUMENTATION");
export const DOCUMENTATION = translation("DOCUMENTATION");
export const EDITEMAIL = translation("EDITEMAIL");
export const DELETEACCOUNTDESCRIPTION = translation("DELETEACCOUNTDESCRIPTION");
export const DELETEACCOUNTDISCALIMER = translation("DELETEACCOUNTDISCALIMER");
export const EDITEMAILDISCALIMER = translation("EDITEMAILDISCALIMER");
export const EDITEMAILDESCRIPTION = translation("EDITEMAILDESCRIPTION");
export const DELETEACCOUNTAPIERROR = translation("DELETEACCOUNTAPIERROR");
export const UPDATEEMAILAPIERROR = translation("UPDATEEMAILAPIERROR");
export const DELETEACCOUNTAPI = "/api/users?userId=";
export const UPDATEEXTERNALEMAILAPI = "/api/users/UpdateExternalEmail?useremail=";
export const ACCOUNT = translation("ACCOUNT");
export const APPLYALLHEADERLABEL = translation("APPLYALLHEADERLABEL");
export const APPLYALLDESCRIPTIONLABEL = translation("APPLYALLDESCRIPTIONLABEL");
export const APPLYTEXT = translation("APPLYTEXT");
export const APPLYALLFAIL = translation("APPLYALLFAIL");
export const APPLYALLSUCCESS = translation("APPLYALLSUCCESS");
export const NOGROUPSFOUND = translation("NOGROUPSFOUND");
export const GETGROUPS = "/api/group/childgroupsbygroupid?gId=";
export const SAVEAPPLYALL = "/api/GroupAppSettings/ApplyGroupAppAettingsToChilds";
export const SAVEAPPGROUPS = "/api/GroupApps";
export const LOCALE_SET_START = "LOCALE_SET_START";
export const LOCALE_SET_FULFILLED = "LOCALE_SET_FULFILLED";
export const LOCALE_SET_FAILED = "LOCALE_SET_FAILED";
export const SET_GLOBAL_ADMIN = "SET_GLOBAL_ADMIN";
export const GETUSERLANGUAGE = "/api/users/GetUserLanguage";
export const SETUSERLANGUAGE = "/api/users/UserLanguage?languageCode=";
export const SENDINVITATIONGROUP = "/api/groupUser/SendCSSInvitation";
export const SENDINVITATIONAPP = "/api/AppUsers/SendCSSInvitation";
export const NEWGROUP = translation("NEWGROUP");
export const CHANGEGROUP = translation("CHANGEGROUP");
export const MOVEUSERTO = translation("MOVEUSERTO");
export const GROUPNAME = translation("GROUPNAME");
export const PARENTGROUP = translation("PARENTGROUP");
export const ASSIGNEDGROUP = translation("ASSIGNEDGROUP");
export const ADMINSTRATORS = translation("ADMINISTRATORS");
export const INSIGHTS = translation("INSIGHTS");
export const USERS_LABEL = translation("USERS_LABEL");
export const GROUP_TEXT = translation("GROUP_TEXT");
export const USERS_CHILD_GROUPS = "USERS_CHILD_GROUPS";
export const INCLUDES = "INCLUDES";
export const USERS_PER_GROUP = translation("USERS_PER_GROUP");
export const USERS_ACTIVE_LABEL = translation("USERS_ACTIVE_LABEL");
export const USERS_ACTIVE_MONTH = translation("USERS_ACTIVE_MONTH");
export const USERS_ACTIVE_WEEK = translation("USERS_ACTIVE_WEEK");
export const USERS_ACTIVE_DAY = translation("USERS_ACTIVE_DAY");
export const USERS_ACTIVE_HOUR = translation("USERS_ACTIVE_HOUR");
export const AUTHENTICATED = translation("AUTHENTICATED");
export const NOT_ACTIVE = translation("NOT_ACTIVE");
export const PENDING = translation("PENDING");
export const DEVELOPERTAB = translation("DEVELOPERTAB");
export const PERMISSIONSTAB = translation("PERMISSIONSTAB");
export const PERMISSIONSTABTEXT = "PERMISSIONSTAB";
export const TENANT = translation("TENANT");
export const CLIENTID = translation("CLIENTID");
export const CLIENTSCOPE = translation("CLIENTSCOPE");
export const AUTHORITYBASE = translation("AUTHORITYBASE");
export const AUTHORITYURL = translation("AUTHORITYURL");
export const USERSTEXT = translation("USERSTEXT");
export const SPECIFYONLYONEFIELDERROR = translation("SPECIFYONLYONEFIELDERROR");
export const APPUSERALREADYEXISTERROR = translation("APPUSERALREADYEXISTERROR");
export const USERADDEDTOGROUP = translation("USERADDEDTOGROUP");
export const USERADDEDTOAPP = translation("USERADDEDTOAPP");
export const LOADING = translation("LOADING");
export const ADDAGROUP = "ADDAGROUP";
export const GROUPSTEXT = translation("GROUPS");
export const NOGROUPSCREATED = translation("NOGROUPSCREATED");
export const NAME = translation("NAME");
export const EDIT = translation("EDIT");
export const INVITATIONPENDING = translation("INVITATIONPENDING");
export const EMAIL = translation("EMAIL");
export const HVAC_EMAIL = translation("HVAC_EMAIL");
export const HVAC_EMAIL_SPECIAL_CHAR_ERROR = translation("HVAC_EMAIL_SPECIAL_CHAR_ERROR");
export const UNABLE_TO_ADD_USER = translation("UNABLE_TO_ADD_USER");
export const HVAC_INACTIVE_MESSAGE = "HVAC_INACTIVE_MESSAGE";
export const HVAC_INVALID_MESSAGE = "HVAC_INVALID_MESSAGE";
export const HVAC_EMAIL_PLACEHOLDER = "HVAC_EMAIL_PLACEHOLDER";
export const SEND_HVAC_INVITATION_GROUP = "api/groupUser/SendHvacInvitation";
export const SEND_HVAC_INVITATION_APP = "api/AppUsers/SendHvacInvitation";
export const GET_ALL_USER_STATUS = "/api/users/GetAllUserStatus";
export const OR = translation("OR");
export const USERADD = translation("USERADD");
export const INTERNALUSER = "INTERNALUSER";
export const HVACUSER = "HVACUSER";
export const EXTERNALUSER = "EXTERNALUSER";
export const ACTIVE = translation("ACTIVE");
export const ON = translation("ON");
export const OFF = translation("OFF");
export const INVITE = translation("INVITE");
export const SELECTALL = translation("SELECTALL");
export const DESELECTALL = translation("DESELECTALL");
export const GETINTERNALUSERNAME = "/api/User/GetUtcInternalUsersByName?name=";
export const AppButtonStyles = ({
  addAppButtonLabel: {
    textTransform: 'none',
    fontSize: '1rem',
    color: '#fff',
    padding: '0',
    lineHeight: '18px',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(',')
  },
  addAppButton: {
    textTransform: 'none',
    fontSize: '1rem',
    color: '#fff',
    padding: '0px 10px',
    height: '37px',
    lineHeight: '18px',
    maxWidth: '164px',
    backgroundColor: '#0033ab',
    borderColor: '#0033ab',
    borderRadius: '3px',
    float: 'right',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),

    '&:hover': {
      color: '#fff',
      backgroundColor: '#0033ab',
      borderColor: '#0033ab',
      borderRadius: '3px'
    }
  }
});
export const cardStyles = ({
  backgroundColor: '#ffffff',
  boxShadow: 'none',
  borderRadius: 0,
  height: '150px',
  transition: 'box-shadow .2s ease-out',
});
export const AppCardStyles = ({
  appEditLevelItem: {
    margin: "0px 25px 25px 25px",
    padding: 0
  },
  appLevelItem: {
    padding: '5px !important'
  },
  appCard: {
    ...cardStyles,
    cursor: "default",
    boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.2)"
  },
  card: {
    ...cardStyles,
    cursor: 'pointer',
    '&:hover': {
      border: '2px solid #3f51b5',
      boxShadow: '0 10px 25px rgba(0,0,0,0.2)',
      margin: '-2px'
    },

    '&:focus': {
      border: '2px solid #3f51b5',
      boxShadow: '0 10px 25px rgba(0,0,0,0.2)',
      margin: '-2px',
      outline: 'none'
    },
  },
  media: {
    margin: '0 auto'
  },
  cardHeader: {
    padding: '8px',
    paddingBottom: '1.3rem',
    width: '80%',
    alignItems: "baseline"
  },
  cardContent: {
    display: "flex",
    padding: 0,
    height: "40%"
  },
  appTitle: {
    color: '#000000',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    fontSize: '1em',
    lineHeight: '1.5em',
    textAlign: 'left',
    margin: 0
  }
});
export const AppsStyles = ({
  root: {
    flexGrow: 1,
  },
  appsListTitle: {
    marginBottom: '1rem',
    color: '#000000',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    fontSize: '2rem',
    fontWeight: 'bold',
    lineHeight: '30px',
    padding: '5px'
  },
  gridContainer: {
    width: '100%',
    margin: 0
  },
  appsListNotFound: {
    height: "100%",
    width: "100%",
    color: "black",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: "22px",
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(',')
  },
  ...AppButtonStyles,
  ...AppCardStyles
});

const genericSaveButton = theme => ({
  saveButton: {
    height: '70px',
    backgroundColor: '#0033ab',
    zIndex: '1',
    width: "100%",
    position: "absolute",
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
    },
    bottom: 0
  }
});

const genericUpdateButtton = ({
  updatebutton: {
    height: '34px',
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: '12px',
    lineHeight: '18px',
    backgroundColor: '#ffffff',
    borderColor: '#ffffff',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    color: '#000000',
    textAlign: 'center',
    borderRadius: '3px',
    margin: '19px 15px 17px 15px',
    width: 'calc(100% - 30px)',

    '&:hover': {
      backgroundColor: '#ffffff'
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#ffffff',
    },
    '&:focus': {
      boxShadow: 'none',
      backgroundColor: '#ffffff',
    },
  }
});

export const groupRootContainerStyles = theme => ({
  root: {
    flexGrow: 1,
  },
  appEditRoot: {
    flexGrow: 1,
    overflow: 'hidden'
  },
  gridContainer: {
    width: '100%',
    margin: 0
  },
  appItem: {
    padding: "0 !important"
  },
  appEditTabItem: {
    padding: "0 !important",
    height: '100vh'
  },
  editGroupPaper: {
    boxShadow: 'none'
  },
});

export const GroupStyles = theme => ({
  ...genericSaveButton(theme),
  ...groupRootContainerStyles(theme),
  ...genericUpdateButtton,
  ...commonLabelInputStyle,
  usersPaper: {
    height: '100vh',
    backgroundColor: '#f0f0f0'
  },
  editTitle: {
    height: '48px',
    backgroundColor: '#ffffff'
  },
  editAppContent: {
    overflow: 'hidden auto',
    height: 'calc(100% - 116px)',
    [theme.breakpoints.down('sm')]: {
      height: 'inherit',
    },
  },
  editAddAppContent: {
    overflow: 'hidden auto',
    height: 'calc(100% - 116px)',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100% - 300px)',
    },
  },
  editGroupTitle: {
    margin: 0,
    color: '#000000',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    fontSize: '16px',
    lineHeight: '22px',
    padding: '12px'
  },
  changeGroupName: {
    display: 'none',
    visibility: 'hidden'
  },
  closeIcon: {
    float: 'right',
    position: 'relative',
    top: '50%',
    transform: 'translateY(10%)',
    cursor: 'pointer'
  },
  disclaimer: {
    color: '#000000',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    fontSize: '11px',
    lineHeight: '18px',
    padding: '12px',
    margin: 0
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  inputStyles: {
    padding: '6px 7px 3px',
    overflow: 'auto'
  },
  inputFocused: {
    border: '1px solid #4f4f4f',
    backgroundColor: '#fff',
    borderBottom: 'none',
    color: '#000000 !important',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    fontSize: '12px',
    lineHeight: '18px',
  },
  inputUnderline: {
    '&::before': {
      borderBottom: '1px solid #4f4f4f'
    },
    '&::after': {
      borderBottom: 'none'
    },
    '&:hover:not(.MuiInput-disabled-345):not(.MuiInput-focused-344):not(.MuiInput-error-347):before': {
      borderBottom: '1px solid #4f4f4f'
    }
  },
  disabledTextField: {
    color: '#000000',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    fontSize: '12px',
    lineHeight: '18px',
  },
  disabledLabelRoot: {
    color: '#000000 !important',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    fontSize: '11px',
    lineHeight: '18px',
    transform: 'none'
  },
  optionsButton: {
    marginLeft: "20px",
    color: "#0033ab",
    border: '1px solid #0033ab',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    fontSize: "12px",
    lineHeight: "18px",
    textAlign: "center",
    height: "32px",
    width: "70px",
    borderRadius: "3px",

    '&:hover': {
      backgroundColor: '#0033ab',
      color: "#fff",
      textTransform: "none"
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#0033ab',
      color: "#fff",
      textTransform: "none"
    },
    '&:focus': {
      boxShadow: 'none',
      backgroundColor: '#0033ab',
      color: "#fff",
      textTransform: "none",
      outline: "none"
    },
  },
  optionsButtonText: {
    textTransform: "none",
    padding: "6px 0 8px 0",
  },
  requiredAsterik: {
    paddingRight: 0,
    color: '#000000',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    fontSize: '11px',
    lineHeight: '18px',
    padding: '12px',
    margin: 0
  },
  addSubPermission: {
    color: '#0033ab',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    fontSize: '11px',
    lineHeight: '18px',
    margin: 0,
    cursor: 'pointer'
  },
  addSubPermissionIcon: {
    marginTop: '6px'
  },
  groupName: {
    padding: 0,
    width: 'calc(100% - 40px)'
  },
  groupNameTextField: {
    margin: '12px 20px 6px 6px',
    width: '100%',
  },
  errorText: {
    color: '#eb1616 !important',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    fontSize: '11px',
    lineHeight: '18px',
    marginLeft: '5px'
  }
});

export const AddGroupStyles = theme => ({ ...GroupStyles(theme), ...AddGroupSubStyles(theme), ...ApplyAllGroupsRootSetting(theme) });

export const AddGroupSubStyles = theme => ({
  paperSize: {
    height: '100vh',
    backgroundColor: '#f7f7f7',
    position: "relative"
  },
  textField: {
    margin: '12px 20px 6px 20px',
    width: '100%',
  },
  invalidTextField: {
    border: '1px solid #eb1616',
    color: '#000000 !important',
    backgroundColor: "#fff",
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    fontSize: '12px',
    lineHeight: '18px',
    padding: '6px 7px 3px',

    '&::before': {
      borderBottom: 'none'
    },
    '&::after': {
      borderBottom: 'none'
    },
    '&:hover:not(.MuiInput-disabled-345):not(.MuiInput-focused-344):not(.MuiInput-error-347):before': {
      borderBottom: 'none'
    }
  }
});

export const EditGroupStyles = theme => ({ ...GroupStyles(theme), ...EditGroupSubStyle(theme), ...AppButtonStyles, ...AppCardStyles });

export const EditGroupSubStyle = theme => ({
  parentChangeButton: {
    width: "48%"
  },
  parentGroupContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "8px 21px",
    width: 'calc(100% - 40px)',
    padding: "0px",
    overflow: "hidden"
  },
  parentGroup: {
    display: "flex",
    margin: "14px 9px",
    width: "100%"
  },
  parentGroupSpan: {
    display: "inline-flex",
    width: "50%",
    alignItems: "center"
  },
  optionalField: {
    marginLeft: '12px'
  },
  imageUploadError: {
    margin: '0px 8px'
  },
  adjustDisclaimerPadding: {
    padding: '8px 12px'
  },
  removeDisclaimerPadding: {
    padding: '0px',
    fontWeight: '500',
    fontSize: '13px',
    minHeight: '18px'
  },
  preview: {
    padding: '0px 11px 7px',
    fontWeight: '500'
  },
  cardContent: {
    padding: '7px 8px'
  },
  imageDisclaimerContainer: {
    padding: '0px 14px'
  },
  card: {
    width: '100%',
    height: '177px',
    margin: '0px 25px 25px 25px'
  },
  media: {
    height: '143px'
  },
  appPermissionsTextField: {
    margin: '12px 20px 12px 6px',
    width: '100%',
  },
  permissionSaveIcon: {
    color: '#0033ab',
    cursor: 'pointer',
    marginBottom: '58px'
  },
  permissionCloseIcon: {
    marginBottom: '58px',
    cursor: 'pointer'
  },
  permissionsFieldContainer: {
    display: 'flex',
    marginBottom: '0px',
    backgroundColor: 'white',
    borderTop: "1px solid #d8d0d0"
  },
  permissionsSubFieldContainer: {
    display: 'flex',
    width: '100%',
    height: '35px',
    marginBottom: '0px',
    backgroundColor: '#F7F7F7',
    alignItems: 'center',
    paddingLeft: '13px'
  },
  noPermissions: {
    margin: '0px 0px 0px 27px'
  },
  fieldContainer: {
    display: 'flex',
    width: '100%',
    marginBottom: '0px'
  },
  uploadDiv: {
    display: "flex",
    flexDirection: "row",
    margin: "0px 25px",
    alignItems: 'center'
  },
  uploadFieldContainer: {
    display: 'flex',
    width: '100%',
    marginBottom: '0px',
    flexDirection: 'column'
  },
  fileName: {
    fontWeight: 'bold'
  },
  uploadButton: {
    textTransform: 'none',
    fontSize: '1rem',
    color: '#fff',
    padding: '0px 10px',
    marginRight: '8px',
    height: '32px',
    lineHeight: '18px',
    width: '70px',
    backgroundColor: '#0033ab',
    borderColor: '#0033ab',
    borderRadius: '3px',
    float: 'right',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),

    '&:hover': {
      color: '#fff',
      backgroundColor: '#0033ab',
      borderColor: '#0033ab',
      borderRadius: '3px'
    }
  },
  editPaperSize: {
    height: '100vh',
    [theme.breakpoints.down('sm')]: {
      height: '90vh',
    },
    backgroundColor: '#f7f7f7',
    position: "relative"

  },
  editAppPaperSize: {
    height: '100vh',
    backgroundColor: '#f7f7f7',
    position: "relative",
    [theme.breakpoints.down('sm')]: {
      minHeight: '129vh',
      maxHeight: '169vh',
      height: 'unset'
    },
  },
  rolesField: {
    color: '#000000',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    fontSize: '11px',
    lineHeight: '18px',
    padding: '12px 12px 0px 12px',
    margin: 0
  },
  editTextField: {
    margin: '12px 20px 24px 20px',
    width: '100%',
  },
  rolesSelectField: {
    margin: '12px 10px 6px',
    width: '100%',
  },
  invalidTextField: {
    border: '1px solid #eb1616',
    color: '#000000 !important',
    backgroundColor: "#fff",
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    fontSize: '12px',
    lineHeight: '18px',
    padding: '6px 0px 3px 7px',

    '&::before': {
      borderBottom: 'none'
    },
    '&::after': {
      borderBottom: 'none'
    },
    '&:hover:not(.MuiInput-disabled-345):not(.MuiInput-focused-344):not(.MuiInput-error-347):before': {
      borderBottom: 'none'
    }
  },
  userButtonRootContainerMargin: {
    margin: '12px'
  },
  userButtonRootContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "0px 12px"
  },
  userButtonRoot: {
    opacity: '1',
    border: '1px solid #0033ab',
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: '1rem',
    color: '#0033ab',
    lineHeight: '18px',
    backgroundColor: 'transparent',
    borderRadius: '3px',
    width: "100%",
    minHeight: '32px',
    maxHeight: '64px',
    padding: '6px',
    margin: '0px 5px 5px 0px',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    fontWeight: '400',

    '&:hover': {
      borderRadius: '3px',
      fontWeight: '400',
      boxShadow: 'none',
      backgroundColor: '#0033ab',
      color: 'white'
    },

    '&:focus': {
      borderRadius: '3px',
      fontWeight: '400',
      boxShadow: 'none',
      backgroundColor: '#0033ab',
      color: 'white'
    },

    '&:active': {
      color: '#0033ab',
      backgroundColor: 'transparent',
      borderColor: '#0033ab',
      borderRadius: '3px',
      fontWeight: '400',
    },
  }
});



  export const TabsStyles = ({
    tabsRoot: {
      borderBottom: 'none',
      width: '100%',
      display: 'inline-block'
    },
    tabsIndicator: {
      background: 'none',
    },
    tabRoot: {
      textTransform: 'capitalize',
      fontWeight: 'normal',
    },

    '&$tabSelected': {
      color: '#000000',
      fontFamily: [
        '"Helvetica Neue"',
        'Helvetica',
        'Arial',
        'sans-serif'
      ].join(','),
      fontSize: '16px',
      lineHeight: '22px',
      fontWeight: 'normal',
    },
    tabSelected: {},
    userButtonRoot: {
      opacity: '1',
      boxShadow: 'none',
      backgroundColor: '#0033ab',
      color: '#ffffff'
    },

    '&:active': {
      color: '#fff',
      backgroundColor: '#0033ab',
      borderColor: '#0033ab',
      borderRadius: '3px',
      fontWeight: '400',
      position: 'absolute',
      right: '20px',
  
      '&:hover': {
        borderRadius: '3px',
        fontWeight: '400',
        textDecoration: 'underline',
        boxShadow: 'none',
        backgroundColor: '#0033ab',
        color: '#ffffff'
      },
  
      '&:focus': {
        borderRadius: '3px',
        fontWeight: '400',
        textDecoration: 'underline',
        boxShadow: 'none',
        backgroundColor: '#0033ab',
        color: '#ffffff'
      },
  
      '&:active': {
        color: '#fff',
        backgroundColor: '#0033ab',
        borderColor: '#0033ab',
        borderRadius: '3px',
        fontWeight: '400',
      },
    }
  });

  export const ManageGroupStyles = theme => ({
    root: {
      flexGrow: 1,
      backgroundColor: '#f0f0f0'
    },
    typography: {
      padding: theme.spacing.unit * 3,
    },
    ...TabsStyles,
    ...HeaderTextAndButtonStyles(theme)
  });

export const HeaderTextAndButtonStyles = theme => ({
  spanPermissionText: {
    marginLeft: "3px"
  },
  appAdminText: {
    display: 'flex',
    alignItems: 'center',
    width: '50%',
    margin: '0px 0px 0px 27px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: '0px 0px 6px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
  },
  appAdminButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '50%',
    margin: '0px 27px 0px 0px',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'block'
    },
  },
  userAppButtonRoot: {
    opacity: '1',
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: '1rem',
    color: '#fff',
    lineHeight: '18px',
    backgroundColor: '#0033ab',
    borderRadius: '3px',
    minWidth: 72,
    minHeight: '0',
    height: '35px',
    margin: '8px 5px',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    fontWeight: '400',

    '&:hover': {
      borderRadius: '3px',
      fontWeight: '400',
      textDecoration: 'underline',
      boxShadow: 'none',
      backgroundColor: '#0033ab',
      color: '#ffffff'
    },

    '&:focus': {
      borderRadius: '3px',
      fontWeight: '400',
      textDecoration: 'underline',
      boxShadow: 'none',
      backgroundColor: '#0033ab',
      color: '#ffffff'
    },

    '&:active': {
      color: '#fff',
      backgroundColor: '#0033ab',
      borderColor: '#0033ab',
      borderRadius: '3px',
      fontWeight: '400',
    },
    [theme.breakpoints.down('sm')]: {
      width: '87%'
    },
  }
});


export const TableBodyStyles = ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    marginRight: 8,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: "#f7f7f7",
    '&$checked': {
      transform: 'translateX(12px)',
      color: "#f7f7f7",
      '& + $track': {
        opacity: 1,
        backgroundColor: "#34cb81",
        borderColor: "#34cb81",
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid #666666`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "#666666",
    height: "unset"
  },
  checked: {},
  focusVisible: {}
});

export const TableHeaderStyles = theme => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    marginRight: theme.spacing(1),
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: "#666666",
    '&$checked': {
      transform: 'translateX(12px)',
      color: "#666666",
      '& + $track': {
        opacity: 1,
        backgroundColor: "#e8e8e8",
        border: "1px solid #666"
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    borderRadius: 16 / 2,
    opacity: 1,
    background: "none",
    height: "unset",
    border: "1px solid #666"
  },
  checked: {},
  focusVisible: {},
});

export const DrawerWidth = 240;

export const HeaderDialogContentStyles = ({
  root: {
    margin: 0,
    padding: '1.3rem',
  }
});

export const HeaderDialogActionStyles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit,
    justifyContent: 'flex-start',
    height: '34px',
    boxSizing: 'content-box'
  }

});

export const HeaderDialogStyles = theme => ({
  root: {
    margin: 0,
    padding: '7px',
    backgroundColor: '#f7f7f7',
    color: '#000000',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    fontSize: '1rem',
    lineHeight: '1.3rem',
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: '#000000',
    fontSize: '0.8rem',
    lineHeight: '1.3rem',
    padding: 0
  }
});

export const HeaderStyles = theme => ({
  root: {
    width: '100%',
    display: "flex"
  },
  navBar: {
    backgroundColor: '#0033ab',
    boxShadow: 'none',
    height: '50px'
  },
  navToolBar: {
    minHeight: '50px'
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
    [theme.breakpoints.up("sm")]: {
      display: "none"
    },
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  tabsRoot: {},
  tabsIndicator: {
    display: 'none'
  },
  tabRoot: {
    opacity: '1',
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: '1rem',
    color: '#fff',
    padding: '0',
    lineHeight: '18px',
    backgroundColor: '#565dde',
    borderRadius: '3px',
    minWidth: 72,
    minHeight: '0',
    height: '35px',
    margin: '8px 5px',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    fontWeight: '400',

    '&:hover': {
      borderRadius: '3px',
      fontWeight: '400',
      textDecoration: 'underline',
      boxShadow: '0 5px 0.8rem rgba(0,0,0,0.2)',
      backgroundColor: '#000F7B',
      color: '#ffffff'
    },

    '&:focus': {
      borderRadius: '3px',
      fontWeight: '400',
      textDecoration: 'underline',
      boxShadow: '0 5px 0.8rem rgba(0,0,0,0.2)',
      backgroundColor: '#000F7B',
      color: '#ffffff'
    },

    '&:active': {
      color: '#fff',
      backgroundColor: '#565dde',
      borderColor: '#565dde',
      borderRadius: '3px',
      fontWeight: '400',
    },

    '&$tabSelected': {
      height: '35px',
      color: '#000000',
      fontFamily: [
        '"Helvetica Neue"',
        'Helvetica',
        'Arial',
        'sans-serif'
      ].join(','),
      fontSize: '1rem',
      lineHeight: '18px',
      textAlign: 'center',
      textDecoration: 'underline',
      backgroundColor: '#ffffff',
      borderRadius: '3px',
      boxShadow: 'none',
      textTransform: 'none',
      margin: '8px 5px',
      fontWeight: '400',
      minHeight: '0',
    }
  },
  tabSelected: {},
  tabLabelContainer: {
    padding: '0.5rem 1rem'
  },
  accountButtonRoot: {
    width: '34px',
    height: '34px',
    backgroundColor: '#000F7B',
    margin: '8px',
    padding: 0,
    '&:hover': {
      backgroundColor: '#000F7B',
    },
  },
  accountButtonRootOutline: {
    width: '34px',
    height: '34px',
    backgroundColor: '#565dde',
    border: '2px solid #fff',
    margin: '8px',
    padding: 0,
    '&:hover': {
      backgroundColor: '#565dde',
      cursor: 'pointer'
    },
  },
  userAccountDialogPaper: {
    borderRadius: '0px',
    minHeight: '172px',
    width: '300px',
    right: 0,
    position: 'absolute',
    margin: 0,
    top: '50px'
  },
  userAccountDialogBackdrop: {
    background: 'none'
  },
  logoutButtonLabel: {
    textTransform: 'none',
    fontSize: '1rem',
    color: '#fff',
    padding: '0',
    lineHeight: '18px',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(',')
  },
  logoutButton: {
    textTransform: 'none',
    fontSize: '1rem',
    color: '#fff',
    padding: '0px 10px',
    height: '100%',
    lineHeight: '18px',
    backgroundColor: '#0033ab',
    borderColor: '#0033ab',
    borderRadius: '3px',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),

    '&:hover': {
      color: '#fff',
      backgroundColor: '#0033ab',
      borderColor: '#0033ab',
      borderRadius: '3px'
    }
  },
  deleteAccountLabel: {
    textTransform: 'none',
    fontSize: '1rem',
    color: '#0033ab',
    boxShadow: 'none',
    padding: '0',
    lineHeight: '18px',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(',')
  },
  deleteAccount: {
    textTransform: 'none',
    fontSize: '1rem',
    color: '#0033ab',
    textDecoration: "underline",
    padding: '0',
    lineHeight: '18px',
    backgroundColor: 'transparent',
    flexDirection: 'row-reverse',
    width: "53%",
    justifyContent: "flex-end",
    boxShadow: 'none',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),

    '&:hover': {
      color: '#0033ab',
      backgroundColor: 'transparent'
    }
  },
  updateEmail: {
    textTransform: 'none',
    fontSize: '1rem',
    color: '#0033ab',
    textDecoration: "underline",
    padding: '0',
    lineHeight: '18px',
    backgroundColor: 'transparent',
    flexDirection: 'row-reverse',
    width: "40%",
    justifyContent: "flex-end",
    boxShadow: 'none',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),

    '&:hover': {
      color: '#0033ab',
      backgroundColor: 'transparent'
    }
  },
  updateEmailLabel: {
    textTransform: 'none',
    fontSize: '1rem',
    color: '#0033ab',
    boxShadow: 'none',
    padding: '0',
    lineHeight: '18px',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(',')
  },
  editEmailSm: {
    textTransform: 'none',
    fontSize: '1rem',
    color: 'white',
    boxShadow: 'none',
    padding: '0',
    lineHeight: '18px',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(',')
  },
  editEmailSm: {
    textTransform: 'none',
    fontSize: '1rem',
    color: 'white',
    textDecoration: "underline",
    padding: '0',
    lineHeight: '18px',
    backgroundColor: 'transparent',
    flexDirection: 'row-reverse',
    boxShadow: 'none',
    margin: '0px 5px',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),

    '&:hover': {
      color: 'white',
      backgroundColor: 'transparent'
    },
  },
  deleteAccountLabelSm: {
    textTransform: 'none',
    fontSize: '1rem',
    color: 'white',
    boxShadow: 'none',
    padding: '0',
    lineHeight: '18px',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(',')
  },
  deleteAccountSm: {
    textTransform: 'none',
    fontSize: '1rem',
    color: 'white',
    textDecoration: "underline",
    padding: '0',
    lineHeight: '18px',
    backgroundColor: 'transparent',
    flexDirection: 'row-reverse',
    boxShadow: 'none',
    margin: '0px 5px',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),

    '&:hover': {
      color: 'white',
      backgroundColor: 'transparent'
    }
  },
  list: {
    width: 250
  },
  fullList: {
    width: "auto"
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: DrawerWidth,
      flexShrink: 0
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: DrawerWidth,
    backgroundColor: '#000f7b',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3
  },
  listItemIcon: {
    color: '#fff'
  },
  listItemUserIcon: {
    color: '#fff',
    backgroundColor: '#0033ab',
    borderRadius: '50%',
    padding: '5px',
    marginRight: '5px',
    minWidth: '0'
  },
  listItemLogoutButton: {
    height: '34px'
  },
  listItemText: {
    color: '#FFFFFF',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    fontSize: '1rem',
    lineHeight: '18px',
  },
  menuItem: {

    '&$selectedListItem': {
      backgroundColor: '#565dde',

      '&:hover': {
        backgroundColor: '#565dde'
      }
    }
  },
  menuItemUser: {
    position: 'fixed',
    bottom: '73px',

    '&$selectedListItem': {
      backgroundColor: '#565dde',

      '&:hover': {
        backgroundColor: '#565dde'
      }
    }
  },
  menuItemEditMail: {
    position: 'fixed',
    bottom: '47px',

    '&$selectedListItem': {
      backgroundColor: '#565dde',

      '&:hover': {
        backgroundColor: '#565dde'
      }
    }
  },
  menuItemLogoutButton: {
    cursor: 'default',
    position: 'fixed',
    bottom: '7px',

    '&$selectedListItem': {
      backgroundColor: '#565dde',

      '&:hover': {
        backgroundColor: '#565dde'
      }
    }
  },
  selectedListItem: {},
  userNameText: {
    color: '#000000',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    fontSize: '2rem',
    fontWeight: 'bold',
    lineHeight: '30px',
    marginBottom: 0
  },
  drawercloseButton: {
    position: "absolute",
    right: theme.spacing.unit,
    top: theme.spacing.unit,
  },
  drawerDivider: {
    height: 0
  },
  headerGutters: {
    paddingLeft: '1rem',
    paddingRight: '1rem'
  },
  versionNumber: {
    color: '#000000',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    fontSize: '1rem',
    lineHeight: '18px',
    margin: "0px 5px",
    width: "37%"
  }
});

export const OptionMenuDialogStyles = {
  root: {
    width: "300px",
  },
  deletetitle: {
    color: "#000000",
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    fontSize: "12px"
  },
  deleteDialogBackdrop: {
    background: 'none'
  }
};

export const OptionMenuStyles = ({
  optionsButton: {
    color: "#0033ab",
    border: '1px solid #0033ab',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    fontSize: "12px",
    lineHeight: "18px",
    textAlign: "center",
    height: "32px",
    width: "100%",
    borderRadius: "3px",

    '&:hover': {
      backgroundColor: '#0033ab',
      color: "#fff",
      textTransform: "none"
    },
    '&:focus': {
      boxShadow: 'none',
      color: "#0033ab",
      textTransform: "none",
      outline: "none"
    },
  },
  optionsButtonText: {
    textTransform: "none",
    padding: "6px 0 8px 0",
    "&:hover": {
      color: "#fff",
    }
  },
  optionsMenuPaper: {
    backgroundColor: "#ffffff",
    width: "150px"
  },
  optionsMenuItem: {
    minHeight: "32px",
    color: "#0033ab",
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    fontSize: "12px",
    lineHeight: "18px",
    textAlign: "center",

    "&:hover": {
      textDecoration: "underline",
      color: "#000",
      fontFamily: [
        '"Helvetica Neue"',
        'Helvetica',
        'Arial',
        'sans-serif'
      ].join(','),
      fontSize: "12px",
      lineHeight: "18px",
      textAlign: "center",
    }
  }
});

export const ToastMessageStyle1 = theme => ({
  success: {
    backgroundColor: '#34CB81',
    height: 40
  },
  error: {
    backgroundColor: theme.palette.error.dark,
    height: 40
  },
  warning: {
    backgroundColor: 'orange',
    height: 40
  },
  icon: {
    fontSize: 15,
    float: 'left',
    color: 'white'
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 5,
    color: 'white',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    fontSize: '12px',
  },
});

export const ToastMessageStyle2 = theme => ({
  margin: {
    margin: theme.spacing(1),
  },
});

export const commonLabelInputStyle = ({
  inputRoot: {
    color: '#000000',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    fontSize: '12px',
    lineHeight: '18px'
  },
  labelRoot: {
    color: '#000000',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    fontSize: '11px',
    lineHeight: '18px',
    transform: 'none'
  },
  labelFocused: {
    color: '#000000 !important',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    fontSize: '11px',
    lineHeight: '18px',
    transform: 'none',
    marginBottom: '3px'
  }
});

export const SendInvitationStyles = theme => ({
  ...genericSaveButton(theme),
  ...genericUpdateButtton,
  ...commonLabelInputStyle,
  root: {
    flexGrow: 1,
  },
  inputStyles: {
    padding: '7px 0px 2px 9px',
    color: '#000000',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    fontSize: '12px',
    lineHeight: '18px',

    "&::placeholder": {
      textOverflow: "ellipsis !important",
      fontFamily: [
        '"Helvetica Neue"',
        'Helvetica',
        'Arial',
        'sans-serif'
      ].join(','),
      fontSize: '12px',
      lineHeight: '18px',
      color: '#A6A6A6'
    }

  },
  inputFocused: {
    border: '1px solid #4f4f4f',
    backgroundColor: '#fff',
    borderBottom: '#4f4f4f !important',
    color: '#000000 !important',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    fontSize: '12px',
    lineHeight: '18px',
  },
  inputUnderline: {
    '&::before': {
      borderBottom: '1px solid #a6a6a6'
    },
    '&::after': {
      borderBottom: 'none'
    },
    '&:hover:not(.MuiInput-disabled-345):not(.MuiInput-focused-344):not(.MuiInput-error-347):before': {
      borderBottom: '1px solid #a6a6a6'
    }
  },
  userEmailInfo: {
    minWidth: '345px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    }
  },
  externalUserEmailTextField: {
    margin: '15px 12px',
    width: 'calc(100% - 24px)',
    marginBottom: '6px',
    position: "relative"
  },
  invalidTextField: {
    border: '1px solid #eb1616',
    color: '#000000 !important',
    backgroundColor: "#fff",
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    fontSize: '12px',
    lineHeight: '18px',

    '&::before': {
      borderBottom: 'none'
    },
    '&::after': {
      borderBottom: 'none'
    },
    '&:hover:not(.MuiInput-disabled-345):not(.MuiInput-focused-344):not(.MuiInput-error-347):before': {
      borderBottom: 'none'
    }
  },
  errorText: {
    color: '#eb1616 !important',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    fontSize: '11px',
    lineHeight: '18px',
    marginLeft: '12px'
  },
  internalUserORText: {
    textAlign: 'center',
    marginBottom: 0
  },
  internalUserEmailTextField: {
    position: 'relative'
  },
  disclaimer: {
    color: '#000000',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    fontSize: '11px',
    lineHeight: '18px',
    padding: '12px',
    margin: 0
  }
});

export const UserInviteStyles = theme => ({
  list: {
    width: 250,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },
  fullList: {
    width: 'auto',
  },
  paperRoot: {
    height: '100vh',
    backgroundColor: '#f7f7f7',
    width: '345px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%'
    }
  },
  editTitle: {
    height: '48px',
    backgroundColor: '#ffffff',
    width: '345px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },
  editGroupTitle: {
    margin: 0,
    color: '#000000',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    fontSize: '16px',
    lineHeight: '22px',
    padding: '12px'
  },
  closeIcon: {
    float: 'right',
    position: 'relative',
    top: '50%',
    transform: 'translateY(10%)',
    cursor: 'pointer'
  }
});

export const SettingsDrawerStyles = theme => ({
  ...HeaderTextAndButtonStyles(theme),
  list: {
    width: "100%",
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  appContainerList: {
    width: "100%",
    display: 'flex',
    flexDirection: 'column',
    height: '99vh',
    [theme.breakpoints.down('sm')]: {
      height: '81vh'
    },
  },
  disclaimer: {
    color: '#000000',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    fontSize: '11px',
    lineHeight: '18px',
    padding: '12px',
    margin: 0,
    fontWeight: 500
  },
  paperRoot: {
    height: '100%',
    backgroundColor: '#f7f7f7',
    width: '50vw',
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
    },
    overflow: 'hidden'
  },
  editTitle: {
    height: '48px',
    backgroundColor: '#ffffff',
    width: '100%',
    marginBottom: "10px"
  },
  editGroupTitle: {
    margin: 0,
    color: '#000000',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '22px',
    padding: '12px'
  },
  loadingTitle: {
    margin: "0px auto",
    color: '#000000',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '22px',
    padding: '12px'
  },
  appsGroupsNoAccess: {
    margin: "16px 24px 0px 24px",
  },
  closeIcon: {
    float: 'right',
    position: 'relative',
    top: '50%',
    transform: 'translateY(10%)',
    cursor: 'pointer'
  },
  disableRootSettings: {
    flexDirection: 'column',
    height: 'inherit',
    overflowX: 'hidden',
    overflowY: 'auto',
    opacity: 0.5,
    pointerEvents: "none"
  },
  settingsSaveButton: {
    height: '70px',
    backgroundColor: '#0033ab',
    width: "100%",
    bottom: 0
  },
  loadingAppTitle: {
    margin: "0px 24px",
  },
  ...ApplyAllGroupsRootSetting(theme),
  ...genericUpdateButtton
});

export const ApplyAllGroupsRootSetting = theme => ({
  rootSettings: {
    flexDirection: 'column',
    height: '93%',
    overflowX: 'hidden',
    overflowY: 'auto'
  },
  changeGroupsListContainer: {
    marginTop: "6px",
    width: "100%",
    position: "relative"
  },
  GroupSearchBox: {
    margin : "0px 0px 10px 6px"
  },
  groupSuggestion: {
  listStyleType: "none",
  padding: "5px",
  '&:hover': {
    backgroundColor: "#DCDCDC",
  }
  },
  changeGroupRootSettings: {
    flexDirection: 'column',
    height: props => props.customHeight || '70vh',
    overflowX: 'hidden',
    overflowY: 'auto'
  },
  loadingChangeGroup: {
    margin: "9px auto",
    fontSize: "14px"
  }
});

export const CustomCheckBoxStyles = {
  root: {
    color: "grey",
    "&$checked": {
      color: "#5a5757"
    }
  },
  checked: {}
};

export const RolesMenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      minWidth: 200
    }
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  }
};

export const OptionMenuEditEmailDialogStyles = {
  root: {
    width: "400px",
  },
  editemailtitle: {
    color: "#000000",
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    fontSize: "12px"
  },
  editemaildisclaimer: {
    color: "#000000",
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    fontSize: "12px",
    fontStyle: "italic"
  },
  errorText: {
    color: '#eb1616 !important',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    fontSize: '11px',
    lineHeight: '18px',
    marginLeft: '12px'
  },
  deleteDialogBackdrop: {
    background: 'none'
  },
  editEmailSm: {
    textTransform: 'none',
    fontSize: '1rem',
    color: 'white',
    boxShadow: 'none',
    padding: '0',
    lineHeight: '18px',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(',')
  },
  editEmailSm: {
    textTransform: 'none',
    fontSize: '1rem',
    color: 'white',
    textDecoration: "underline",
    padding: '0',
    lineHeight: '18px',
    backgroundColor: 'transparent',
    flexDirection: 'row-reverse',
    boxShadow: 'none',
    margin: '0px 5px',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),

    '&:hover': {
      color: 'white',
      backgroundColor: 'transparent'
    }
  },
};
