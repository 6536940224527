import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { OptionMenuEditEmailDialogStyles, CONFIRMATIONTEXT, CANCELTEXT } from '../../../Common/Constants/constants';

const useStyles = makeStyles(OptionMenuEditEmailDialogStyles);

function UpdateEmailPopUp(props) {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={props.opennow}
        onClose={props.onCloseEvent}
        aria-labelledby="responsive-dialog-title"
        classes={{ paper: classes.root }}
      >
        <DialogTitle id="responsive-dialog-title"> {props.dialogHead}</DialogTitle>
        <DialogContent id="addbg">
          <DialogContentText>
            <p className={classes.editemailtitle}>{props.dialogContent}</p>
            <p className={classes.editemaildisclaimer}> {(props.dialogDisclaimer) ? props.dialogDisclaimer : CONFIRMATIONTEXT}</p>
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="updatedEmail"
            label="Email Address"
            type="email"
            fullWidth
            onChange={props.handleTextFieldChange}
            value={props.updatedEmail}
          />
          {props.emailError &&
            <span className={classes.errorText}>{props.emailError}</span>}
        </DialogContent>
        <DialogActions id="dialogbutton">
          {(props.isDisabled) ?
            <>
              {props.updatedEmail &&
                <Button disabled color="secondary" variant="outlined" >
                  {props.dialogButtonText}
                </Button>}
              <Button disabled color="secondary" variant="outlined" >
                {CANCELTEXT}
              </Button>
            </>
            :
            <>
              {props.updatedEmail &&
                <Button onClick={props.onSaveClick} color="primary" variant="outlined" >
                  {props.dialogButtonText}
                </Button>}
              <Button onClick={props.onCloseEvent} color="primary" variant="outlined" autoFocus>
                {CANCELTEXT}
              </Button>
            </>
          }
        </DialogActions>
      </Dialog>
    </div>
  );
}

UpdateEmailPopUp.propTypes = {
  fullScreen: PropTypes.bool.isRequired
};

export default withMobileDialog()(UpdateEmailPopUp);