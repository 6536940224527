import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import Apps from '../Components/Apps/Apps'
import Groups from '../Components/Groups/Groups'
import App from '../App';
import authcallback from '../Components/Shared/Authcallback/authcallback';
import Administrator from '../Components/Shared/Administrator/Administrator';
import MigrationError from '../Components/Shared/MigrationError/MigrationError';
import InvalidHvacError from '../Components/Shared/InvalidHvacError';
import EditApp from '../Components/Apps/EditApp';
import AddGroup from '../Components/Groups/addGroup/addGroup';
import Header from '../Components/Shared/Header/Header';
import { connect } from 'react-redux';
import messages from '../Common/Utilities/messages';
import { IntlProvider } from 'react-intl';
import Footer from '../Components/Shared/footer/footer';

class Routes extends React.Component {
    showHeader = (props) => {
        const {pathname} = props.location;
        return (pathname !== "/addGroup") &&
        (pathname !== "/authcallback") &&
        (pathname.includes("/editGroup") !== true) && (pathname.includes("/EditApp") !== true)
    }
    render() {
        const {lang} = this.props;
        return (
            <IntlProvider locale={lang} messages={messages[lang]}>
                <BrowserRouter>
                    <Route path="/" component={App} />
                    <Route path="/" render={(props) => this.showHeader(props) && <><Header /> <Footer /></>} />
                    <Route path="/Groups" component={Groups} />
                    <Route path="/Administrator" component={Administrator} />
                    <Route path="/dashboard" component={Apps} />
                    <Route path="/migrationError" component={MigrationError} />
                    <Route path="/invalidHvacError" component={InvalidHvacError} />
                    <Route path="/authcallback" component={authcallback} />
                    <Route path="/EditApp" render={(props) =>
                    <EditApp {...props}/>}/>
                    <Route path="/addGroup" component={AddGroup} />
                </BrowserRouter>
            </IntlProvider>
        )
    }
}

const mapStateToProps = (state) => ({
    lang: state.locale.lang
  });
  
export default connect(mapStateToProps)(Routes);
