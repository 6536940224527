import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DialogBox from "../../Shared/OptionsMenu/dialog";
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent";
import CardMedia from '@material-ui/core/CardMedia';
import CardHeader from '@material-ui/core/CardHeader';
import { ReactComponent as EditClose } from '../../../Assests/Images/EditGroupClose.svg';
import ToastMessages from '../../Shared/Toast/ToastMessages';
import { EditGroupStyles, DEVURLDISCLAIMER, GROUPLENGTH50, DEVURL, ADDEDITAPP, GETAPPDETAILSFAIL, GETAPPDETAILS, UNSAVEDCHANGESHEADERTEXT, DELETEAPPHEADER, DELETEAPPCONTENT, SAVETEXT, UNSAVEDCHANGESDESCRIPTION, INVALIDURL, DASHBOARD, FIELDREQ, GROUPLENGTH,
   REQUIREDFIELDDISCLAIMERTEXT, NEWAPP, EDITAPP, APPUPDATESUCCESS, APPCREATESUCCESS, APPPERMISSIONNAME, APPSAVEERROR, APPALREADYEXISTERROR, APPNOCHANGES, CREATEAPP, APPDELETE, PREVIEW, CONTINUETEXT, APPNAME, URL, IMAGE, UPLOAD, IMGSIZEERROR, IMGTYPEERROR, IMGRESERROR, IMGUPLOADDISCLAIMER } from '../../../Common/Constants/constants';
import CustomTextField from '../../Groups/CustomTextField';
import axiosInstance from '../../Shared/Interceptor/interceptor';
import appConfig from '../../../Environment/environments';
import { withRouter } from 'react-router';
import DeletePopUp from '../../Shared/OptionsMenu/DeletePopUp';
import { encryptData } from '../../../Common/Utilities/utility';
import Grid from '@material-ui/core/Grid';
const styles = EditGroupStyles;

class AppDetails extends React.Component {

  constructor(props) {
    super(props);
  }

  state = {
    statusMessage: false,
    imageUploading: false,
    message: '',
    toastVPosition: 'top',
    toastHPosition: 'left',
    messageVariant: '',
    changed: false,
    loggedInUserType: '',
    opennow: false,
    fields: [
              {"fieldName": APPNAME, "error": null, value: "", changed: false, originalValue: "", disabled: false},
              {"fieldName": URL, "error": null, value: "", changed: false, originalValue: "", disabled: false},
              {"fieldName": DEVURL, "error": null, value: "", changed: false, originalValue: "", disabled: false},
              {"fieldName": APPPERMISSIONNAME, "error": null, value: "", changed: false, originalValue: "", disabled: false}
            ],
    imageBase64Data: '',
    imageType: '',
    fileName: '',
    imageURL: '',
    imageUploadError: '',
    imageChanged: false
  }

  componentDidMount() {
    ((this.props.appId !== '' && this.props.type === 'edit') || (this.props.location && this.props.location.appId !== '' && this.props.location.type === 'edit')) && this.getAppDetails(this.props.appId);
  }

  getAppDetails = (appId) => {
    axiosInstance.get(`${appConfig.api.development}${GETAPPDETAILS}${encryptData(appId)}`, 
      {
        method: 'GET',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
      })
      .then(res => {
        let fieldData = this.state.fields;
        let devURL = res.data.devURL;
        fieldData[0].value = res.data.name;
        fieldData[0].originalValue = res.data.name;
        fieldData[1].value = res.data.url;
        fieldData[1].originalValue = res.data.url;
        fieldData[2].value = devURL;
        fieldData[2].originalValue = devURL;
        fieldData[3].value = res.data.appDesc;
        fieldData[3].originalValue = res.data.appDesc;
        this.setState({
          imageURL: `data:image/${res.data.logoImageType};base64,${res.data.logo}`,
          fields: fieldData,
          imageBase64Data: res.data.logo
        }, () => {
          (this.props.type === 'edit') && this.props.setAppName(fieldData[0].value);
        });
      })
      .catch(e => {
        this.setState({
          statusMessage: true,
          message: GETAPPDETAILSFAIL,
          messageVariant: "error",
        });
      });
  }

  isValuesChangd = () => {
    return this.state.fields[0].changed || this.state.fields[1].changed || this.state.fields[3].changed || this.state.fields[2].changed || this.state.imageChanged
  }

  onCloseHandler = () => {
    if(this.props.onClose) {
      this.props.onClose();
    }
    else if (this.state.imageChanged || this.isValuesChangd()) {
      this.setState({
        opennow: true
      });
    }
    else {
      this.props.history.push({
        pathname: DASHBOARD
      });
    }
  }

  removeDialogPopup = () => {
    this.setState({ opennow: false});
  }

  onSubmitHandler = (e) => {
    e.preventDefault();
    this.fieldChangeHandler(this.state.fields[0].value, 0);
    this.fieldChangeHandler(this.state.fields[1].value, 1);
    this.fieldChangeHandler(this.state.fields[2].value, 2);
    this.fieldChangeHandler(this.state.fields[3].value, 3);
    if (!this.isValuesChangd() && (this.props.type == 'edit'))
    {
      this.setState({
        statusMessage: true,
        message: APPNOCHANGES,
        messageVariant: "warning",
      });
    }
    else if (this.state.fields[0].error === null && this.state.fields[1].error === null && this.state.fields[2].error === null && this.state.fields[3].error === null && this.state.imageBase64Data !== '')
    {
      let obj = {
        "name": this.state.fields[0].value,
        "logo": this.state.imageBase64Data,
        "logoImageType": this.state.imageType,
        "url": this.state.fields[1].value,
        "logoImageName": this.state.fields[0].value,
        "appDesc": this.state.fields[3].value,
        "devURL": (this.state.fields[2].value === "") ? null : this.state.fields[2].value
      };
      let data;
      let request;
      if (this.props.type == 'edit')
      { 
        obj.Id = this.props.appId;
        data = JSON.stringify(obj);
        request = axiosInstance.put(`${appConfig.api.development}${CREATEAPP}/${this.props.appId}`, data, 
        {
          method: 'PUT',
          headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
        });
      }
      else {
        data = JSON.stringify(obj);
        request = axiosInstance.post(`${appConfig.api.development}${CREATEAPP}`, data, 
        {
          method: 'POST',
          headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
        });
      }

      request.then(res => {
        let fieldData = this.state.fields;
        fieldData[0].value = fieldData[0].originalValue = fieldData[0].value;
        fieldData[0].changed = false;
        fieldData[1].value = fieldData[1].originalValue = fieldData[1].value;
        fieldData[1].changed = false;
        fieldData[2].value = fieldData[2].originalValue = fieldData[2].value;
        fieldData[2].changed = false;
        fieldData[3].value = fieldData[3].originalValue = fieldData[3].value;
        fieldData[3].changed = false;
        this.setState({
          statusMessage: true,
          message: (this.props.type == 'edit') ? APPUPDATESUCCESS : APPCREATESUCCESS,
          messageVariant: "success",
          fields: fieldData,
          imageChanged: false
        }, () => {
          (this.props.type !== 'edit') && this.redirectToEditApp(res.data);
          (this.props.type === 'edit') && this.props.setAppName(fieldData[0].value)
        });
      })
      .catch((e) => {
        let errorMessage = (e.response.data.message.indexOf("App name cannot be duplicated") > -1) ? APPALREADYEXISTERROR : APPSAVEERROR;
        this.setState({
          statusMessage: true,
          message: errorMessage,
          messageVariant: "error",
        });
      });
    }
    else if (this.state.imageBase64Data === '') 
    {
      this.setState({
        imageUploadError: FIELDREQ
      });
    }
  }

  handleToast(event, reason) {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      statusMessage: false
    });
  }

  fieldChangeHandler = (targetValue, index) => {
    let fieldData = this.state.fields;
    fieldData[index].changed = (this.state.fields[index].originalValue !== targetValue) ? true : false;
    fieldData[index].value = targetValue && targetValue.trimStart();
    this.setState({
      fields: fieldData
    }, () => {
      this.validateField(index);
    });
  };

  isUrl = (url, isDev=false) => {
    const httpsLocalRegExp = /^(https):\/\/(((localhost)(:+)([0-9])*)|(localhost\/|localhost$))/;
    const regexp = (isDev) ? /(http):\/\/(localhost)(:[0-9]+)(\w*)/ : /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
    return regexp.test(url) && !(httpsLocalRegExp.test(url));
  }

  validateField = (index) => {
    let fieldData = this.state.fields;
    if (index === 0)
    {
      fieldData[index].error = ((fieldData[index].value == "") ? FIELDREQ : (fieldData[index].value.length > 100 ? GROUPLENGTH : null));
    }
    else if (index === 3)
    {
      fieldData[index].error = ((fieldData[index].value == "") ? FIELDREQ : (fieldData[index].value.length > 50 ? GROUPLENGTH50 : null));
    }
    else if (index === 2)
    {
      if (fieldData[index].value === "" || fieldData[index].value === null) {
        fieldData[index].value = null;
        fieldData[index].error = null;
      }
      else {
        let devURLs = fieldData[index].value.split(';');
        let urlValidity = false;
        (devURLs[(devURLs.length-1)] === "") && devURLs.pop();
        let trailingSemiColonRegEx = /;$/
        devURLs.map(value => { 
          urlValidity = (value === "") ? urlValidity : this.isUrl(value, true)
        }, this);
        let semiColonTest = (devURLs.length === 1) ? true : (trailingSemiColonRegEx.test(fieldData[index].value) === true); 
        fieldData[index].error = (urlValidity && semiColonTest) ? null : INVALIDURL;
      }
    }
    else {
      fieldData[index].error = ((fieldData[index].value == "") ? FIELDREQ : (fieldData[index].value.length > 100 ? GROUPLENGTH : (this.isUrl(fieldData[index].value) ? null : INVALIDURL)));
    }
    ((this.props.type === 'edit') && (index === 0)) && this.props.setAppName(fieldData[index].value);
    this.setState({
      fields: fieldData
    });
  }

  showUpload = () => {
    document.getElementById('file').click()
  }

  fileUploadImageLoadCallback = (imgwidth, imgHeight) => {
    if( imgwidth > 300 || imgHeight > 300){
      this.setState({
        imageBase64Data: '',
        imageURL: '',
        imageUploadError: IMGRESERROR,
        imageType: '',
        fileName: '',
        imageChanged: (this.props.type === 'edit') ? true : false
      });
    }
  }

  clearFileContent = (e) => {
    e.target.value = '';
  }

  onfileUpload = (e) => {
    this.img = new Image();
    this.fileSizeCheck = 0;
    this.fileTypeCheck =  false;
    this.fileSizeCheck = (e.target.files[0]) ? (e.target.files[0].size <= 1048576) : false;
    this.fileTypeCheck = (e.target.files[0]) ? ((e.target.files[0].type === "image/jpeg") || (e.target.files[0].type === "image/png") || (e.target.files[0].type === "image/bmp")) : false;
    this.fileType = (this.fileSizeCheck && this.fileTypeCheck) ? e.target.files[0].type.split('/')[1] : '';
    this.fileName = (this.fileSizeCheck && this.fileTypeCheck) ? e.target.files[0].name : '';
    let reader = new FileReader();
    let URL = window.URL || window.webkitURL;
    this.imageURL = (e.target.files[0]) ? URL.createObjectURL(e.target.files[0]) : '';
    let _this = this;
    if ( this.fileSizeCheck && this.fileTypeCheck ) {
      reader.readAsDataURL(e.target.files[0]);
      reader.onloadend = function() {
        _this.setState({
          imageURL: _this.imageURL,
          imageBase64Data: reader.result.split(',')[1],
          imageUploadError: '',
          imageType: _this.fileType,
          fileName: _this.fileName,
          imageChanged: true
        });
      }
    }
    else if (this.fileSizeCheck === false) {
      this.setState({
        imageBase64Data: '',
        imageURL: '',
        imageUploadError: IMGSIZEERROR,
        imageType: '',
        fileName: '',
        imageChanged: (this.props.type === 'edit') ? true : false
      });
    }
    else if (this.fileTypeCheck === false) {
      this.setState({
        imageBase64Data: '',
        imageURL: '',
        imageUploadError: IMGTYPEERROR,
        imageType: '',
        fileName: '',
        imageChanged: (this.props.type === 'edit') ? true : false
      });
    }
    
    this.img.onload = function() {
      _this.fileUploadImageLoadCallback(this.width, this.height);
    }
    
    this.img.src = (e.target.files[0]) ? URL.createObjectURL(e.target.files[0]) : '';
  }

  redirectToDashboard = () => {
    if (this.props.appId !== '' && this.props.type === 'edit') {
      this.onCloseHandler();
    }
    
    if (this.props.location && this.props.location.appId !== '' && this.props.location.type === 'edit') {
      this.props.history.push({
        pathname: DASHBOARD
      });
    }
  }

  redirectToEditApp = (id) => {
    this.props.history.push({
      pathname: ADDEDITAPP,
      id: id,
      type: 'edit'
    })
  }

  deleteHandler = () => {
    axiosInstance.delete(appConfig.api.development + APPDELETE + encryptData(this.props.appId).toString() ,
      {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.props.history.push(DASHBOARD);
      })
      .catch(function (error) {
        console.log(error);
      })
  };

  render() {
    const { classes } = this.props;
    return (
      <>
      <Paper square={true} classes={{ root: classes.editAppPaperSize, elevation1: classes.editGroupPaper }}>
          <div className={classes.editTitle}>
            <p className={classes.editGroupTitle}>{(this.props.type === 'edit') ?  EDITAPP : NEWAPP}
            <span className={classes.closeIcon} onClick={(e) => this.onCloseHandler()}>
                <EditClose/>
              </span>
            </p>
          </div>
          <div className={(this.props.type === 'edit') ? classes.editAppContent : classes.editAddAppContent}>
            <Typography classes={{ root: classNames(classes.disclaimer, classes.adjustDisclaimerPadding) }}>
              {REQUIREDFIELDDISCLAIMERTEXT}
            </Typography>

            <form className={classes.container} onSubmit={(e) => this.onSubmitHandler(e)} noValidate>
              {
                this.state.fields.map((field, index) => {
                  return <div key={index} className={classes.fieldContainer}>
                    {
                      (index !== 2) 
                      ?
                      <>
                      <span className={classes.requiredAsterik}>
                      *
                      </span>
                      <span className={classes.groupName}>
                      <CustomTextField disabled={field.disabled} id={index} classes={classes} label={field.fieldName} name={field.value} nameError={field.error} nameChangeHandler={(e) => this.fieldChangeHandler(e.target.value, index)} validateName={(e) => this.validateField(index)} />
                      <div className={classes.errorText}>{field.error}</div>
                      </span>
                      </>
                      :
                      <span className={classNames(classes.groupName, classes.optionalField)}>
                      <CustomTextField disabled={field.disabled} id={index} classes={classes} label={field.fieldName} name={field.value} nameError={field.error} nameChangeHandler={(e) => this.fieldChangeHandler(e.target.value, index)} validateName={(e) => this.validateField(index)} />
                      <div className={classes.errorText}>{field.error}</div>
                      <Typography classes={{ root: classNames(classes.disclaimer, classes.adjustDisclaimerPadding) }}>
                        {DEVURLDISCLAIMER}
                      </Typography>
                      </span>
                    }
                  </div>
                })
              }
              <div className={classes.uploadFieldContainer}>
                <pre>
                    <span className={classes.requiredAsterik}>
                      *    {IMAGE}
                    </span>
                </pre>
                <div className={classes.uploadDiv}>
                    <Button variant="contained" color="primary" onClick={this.showUpload} disableRipple classes={{ root: classes.uploadButton, label: classes.addAppButtonLabel}}>{UPLOAD}</Button>
                    <input type='file' id="file" accept="image/jpeg,image/bmp,image/png" onChange={(e) => this.onfileUpload(e)} onClick={(e) => this.clearFileContent(e)} className='hide' />
                    <span className={classes.fileName}>{this.state.fileName}</span>
                </div>
                <div className={classes.imageDisclaimerContainer}>
                  <Typography classes={{ root: classNames(classes.disclaimer, classes.adjustDisclaimerPadding) }}>
                    {IMGUPLOADDISCLAIMER}
                  </Typography>
                  <Typography classes={{ root: classNames(classes.disclaimer, classes.preview) }}>
                    {PREVIEW}
                  </Typography>
                  <Typography classes={{ root: classNames(classes.imageUploadError, classes.errorText) }}>
                      {this.state.imageUploadError}
                  </Typography>
                </div>
              </div>
              <Grid  item xs={12} sm={12} md={12} lg={12} zeroMinWidth key={0} classes={{ item: classes.appEditLevelItem }}>
                <Card className={classes.appCard}>
                  <div className="EditAppContainer">
                    <CardHeader
                      classes={{
                        root: classes.cardHeader,
                        title: classes.appTitle,
                      }}
                      title={this.state.fields[0].value}
                    />
                  </div>
                  <CardContent className={classes.cardContent}>
                    {(this.state.imageURL !== '') ? <CardMedia
                      className={classes.media}
                      component="img"
                      image={`${this.state.imageURL}`}
                      title="" /> : ''
                    }
                  </CardContent>
                </Card>
              </Grid>
              {(this.props.type === 'edit') ? 
                <div className={classNames(classes.userButtonRootContainer, classes.userButtonRootContainerMargin )}>
                  <DeletePopUp header={DELETEAPPHEADER} content={DELETEAPPCONTENT} deleteHandler={() => this.deleteHandler()}/>
                </div>
               : ''}
              <div className={classes.saveButton}>
                  <Button type="submit" variant="contained" disableRipple className={classNames(classes.margin, classes.updatebutton)}>{(this.props.type === 'add' || this.props.location.type === 'add') ? CONTINUETEXT : SAVETEXT }</Button>
              </div>

            </form>
            <ToastMessages statusMessage={this.state.statusMessage} message={this.state.message} variant={this.state.messageVariant} toastHPosition={this.state.toastHPosition} toastVPosition={this.state.toastVPosition} close={this.handleToast.bind(this)} />
          </div>
      </Paper>
      <DialogBox dialogContent={UNSAVEDCHANGESDESCRIPTION} dialogHead={UNSAVEDCHANGESHEADERTEXT} dialogButtonText={CONTINUETEXT} opennow={this.state.opennow} onCloseClick={(e) => this.redirectToDashboard()} onCloseEvent={(e) => this.removeDialogPopup()} />
      </>
    )
  }
}

AppDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(AppDetails));