import { makeStyles } from '@material-ui/core/styles';
const DomainsStyles = makeStyles(theme => ({
    domainContainer: {
        display: "flex",
    },
    domainErrorMessage: {
        color: "#eb1616"
    },
    domainsChipContainer: {
        margin: "7px 0px",
        maxHeight: "100px",
        overflowX: "hidden"
    },
    domainsChip: {
        margin: "3px 3px 0px 3px"
    },
    domainInputContainer: {
        width: "91%",
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "unset"
        },
        "& .MuiInputLabel-outlined.Mui-focused": {
            color: "unset"
        }
    },
    domainInput: {
        padding: "11px 0px 11px 11px"
    }
}));

export default DomainsStyles;