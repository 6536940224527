import {USERS_ACTIVE_MONTH,USERS_ACTIVE_WEEK,USERS_ACTIVE_HOUR,USERS_ACTIVE_DAY} from '../../../Common/Constants/constants';

const createPieChartData = (userCountStatus) => {
  const {pendingUserCount=0,totalUserCount=0,notActiveUserCount=0,authUserCount=0} = userCountStatus;
  if (!totalUserCount) {
    return [{
      "id": "noUsers",
      "value": 1
    }]
  }
  else {
    return [{
      "id": "autheticated",
      "value": authUserCount
    },
    {
      "id": "notActive",
      "value": notActiveUserCount
    },
    {
      "id": "pending",
      "value": pendingUserCount
    }]
  }
}
const createInsightsData = (insightsInfo) => {
  const {activeUserStatus,userCountStatus,totalChildCount,userCountPerGroups} = insightsInfo;
  const {currentlyActiveUser=0,lastHourActive=0,lastDayActive=0,lastWeekActive=0,lastMonthActive=0} = activeUserStatus;
  const {totalUserCount=0} = userCountStatus;
  const {chartGroupNames, groupStatsChartData} = createGroupChartData(userCountPerGroups);
  const pieChartData = createPieChartData(userCountStatus);
  
  return {
    groupStats: {
      totalGroups: totalChildCount,
      groupStatIndexBy: "groupName",
      chartGroupNames: chartGroupNames,
      groupsStatData: groupStatsChartData
    },
    userStats: {
      totalUsersActive: currentlyActiveUser,
      data:[
        {
          "id": "active last month",
          "value": lastMonthActive,
          "label": USERS_ACTIVE_MONTH
        },
        {
          "id": "active last week",
          "value": lastWeekActive,
          "label": USERS_ACTIVE_WEEK
        },
        {
          "id": "active last day",
          "value": lastDayActive,
          "label": USERS_ACTIVE_DAY
        },
        {
          "id": "active last hour",
          "value": lastHourActive,
          "label": USERS_ACTIVE_HOUR
        }
      ]
    },
    authStats: {
      totalUserCount: totalUserCount,
      chartData: pieChartData
    }
  }
}

const createGroupChartData = (userCountPerGroups) => {
  let groupStatsChartData = [];
  let chartGroupNames = [];
  userCountPerGroups.forEach((group,index) => {
    const {groupName,userCount} = group;
    let chartData = {};
    chartData["groupName"] = index;
    chartData[index] = (userCount === 0) ? 0.1 : userCount;
    chartGroupNames.push(groupName);
    groupStatsChartData.push(chartData);
  })
  return {
    chartGroupNames,
    groupStatsChartData
  }
}

const initialData = {
  userStats: {},
  authStats: {},
  groupStats: {},
  loading: true
}

const init = () => {
  return initialData;
}

const insightsReducer = (state, action) => {
  switch (action.type) {
    case 'updateUserStats':
      const {userStats,authStats,groupStats} = action.payload;
      return {
        ...state,
        userStats,
        authStats,
        groupStats,
        loading: false,
      };
    case 'reset':
      return init();
    default:
      return state;
  }
}

export {init,initialData,createInsightsData,insightsReducer};